const FETCH_CONFIG = 'FETCH_CONFIG',
  TOGGLE_OVERLAY = 'TOGGLE_OVERLAY',
  TOGGLE_SATELLITE = 'TOGGLE_SATELLITE';

const BaseMapStoreModule = {
  extend: ({state, mutations, actions, getters}) => ({
    ...BaseMapStoreModule._options,
    state: Object.assign({}, BaseMapStoreModule._state, state),
    mutations: Object.assign({}, BaseMapStoreModule._mutations, mutations),
    actions: Object.assign({}, BaseMapStoreModule._actions, actions),
    getters: Object.assign({}, BaseMapStoreModule._getters, getters),
  }),
  _options: {namespaced: true}, // add 'treeMap/' to things
  _state: {
    config: {},
    overlayExpanded: true, // eh, probably different for different modules/components/maps
    satelliteBaseLayer: false,
    // currentFeature: null, // all 3 maps allow selecting a feature
  },
  _mutations: {
    [FETCH_CONFIG](state, config) {
      state.config = config;
      // state.layers = config.layers;
    },
    [TOGGLE_OVERLAY](state) {
      state.overlayExpanded = !state.overlayExpanded;
    },
    [TOGGLE_SATELLITE](state) {
      state.satelliteBaseLayer = !state.satelliteBaseLayer;
    },
  },
  _actions: {
    toggleOverlay({commit}) {
      // only needs to be in store so selectFeature can change it
      commit(TOGGLE_OVERLAY);
    },
    toggleSatellite({commit}) {
      commit(TOGGLE_SATELLITE);
    },
  },
  _getters: {},
};

export default BaseMapStoreModule;
