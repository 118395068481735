import * as _ from 'lodash';

const attributeNames = [
  'pid',
  'work',
  'status',
  'dueDate',
  'dateCompleted',
  'comments',
  'photos',
];

const associations = ['layer'];

class WorkRecord {
  constructor(attributes) {
    Object.assign(
      this,
      _.pick(attributes, [...attributeNames, ...associations])
    );
  }

  toObject() {
    const object = _.pick(this, attributeNames);

    // object.layer = this.layer.toObject();

    return object;
  }

  // all unix timestamps for comparison
  isBetween(...dates) {
    return (
      this._dateBetween(this.dateCompleted, ...dates) ||
      this._dateBetween(this.dueDate, ...dates)
    );
  }

  _dateBetween(date, start, end) {
    if (!date) return false;

    return date > start && date < end;
  }
}

export default WorkRecord;
