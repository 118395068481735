<template>
  <div class="mapboxgl-ctrl-group mapboxgl-ctrl">
    <v-btn
      @click.prevent="toggleSatellite"
      href="#"
      class="white px-0"
      height="29px"
      width="29px"
      min-width="29px">
      <v-icon>mdi-layers</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {kebabCase} from 'lodash';

export default {
  inject: ['mapSetKey', 'moduleKey'],

  methods: {
    toggleSatellite() {
      const moduleFileName = kebabCase(this.moduleKey);

      import(`@/${moduleFileName}`).then(({UseCases}) => {
        // this depends on their being a UseCases for each map that has satellite
        UseCases.toggleSatellite(this.mapSetKey);
      });
    },
  },
};
</script>

<style scoped lang="scss">
i.mdi-layers {
  font-size: 20px;
}
</style>
