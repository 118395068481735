<template>
  <!-- non-mobile -->
  <div v-if="!mobile">
    <!-- currently selected tree info  -->
    <!-- km: doesn't make sense to have this inside aggregate details, need to change names or configuration -->
    <TreeDetails v-if="tree" :tree="tree" :config="config" />

    <!-- overall details and ecobenefits -->
    <div v-else>
      <EcoBenefits
        v-if="ecoBenefitsEnabled"
        :benefits="benefits"
        :title="`Details${ecoBenefitsEnabled ? ' & Eco Benefits' : ''}`">
        <span class="font-weight-bold">Total Trees:</span> {{ totalTrees }}
      </EcoBenefits>
    </div>
  </div>

  <!-- mobile -->
  <div v-else>
    <!-- currently selected tree info  -->
    <TreeDetails v-if="tree" :tree="tree" />

    <!-- overall details and ecobenefits -->
    <v-tabs v-else height="32px" fixed-tabs>
      <!-- tabs -->
      <v-tab> <v-icon left>mdi-map</v-icon>Legend </v-tab>
      <v-tab>
        <v-icon left>mdi-poll</v-icon>
        details
      </v-tab>

      <!-- content -->

      <!-- legend -->
      <v-tab-item>
        <!-- km 1/27/21: DetailsOverlayCard around LegendSymbology here instead
             of inside since LegendSymbology isn't inside DetailOverlay on desktop -->
        <DetailsOverlayCard>
          <LegendSymbology class="legend-symbology-tab" v-if="loaded" />
        </DetailsOverlayCard>
      </v-tab-item>

      <!-- details and ecobenefits -->
      <v-tab-item>
        <EcoBenefits
          v-if="ecoBenefitsEnabled"
          :benefits="benefits"
          :title="`Details${ecoBenefitsEnabled ? ' & Eco Benefits' : ''}`">
          <span class="font-weight-bold">Total Trees:</span> {{ totalTrees }}
        </EcoBenefits>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {Config, Utils} from '@/shared';
import {DetailsOverlayCard} from '@/map';
import EcoBenefits from './eco-benefits';
import LegendSymbology from '../legend-symbology';
import TreeDetails from './tree-details';

export default {
  components: {
    EcoBenefits,
    LegendSymbology,
    TreeDetails,
    DetailsOverlayCard,
  },
  props: ['tree'],
  inject: ['mapName', 'mapSubName'],
  data: () => ({
    benefits: [],
    config: {},
    loaded: false,
    ecoBenefitsEnabled: false,
    totalTrees: null,
  }),
  computed: {
    mobile() {
      // embedded will not be special cased here due to the
      // desire that mobile functionality apply to embedded on mobile
      return this.$vuetify.breakpoint.mobile; // && !this.embedded;
    },
  },
  created() {
    // km 6/22/21: since config is loaded async, it's easier to pass through
    // customer and setup everything in the then function, rather then wait
    // for config to come through props
    Config.loadTreeMap(this.mapName, this.mapSubName).then(config => {
      // TODO eventually, get config from injected storeModule
      this.config = config;
      this.loaded = true;

      this.totalTrees = config.aggregateData.treeCount.toLocaleString('en');
      this._setupEcoBenefits(config);
    });
  },
  methods: {
    _setupEcoBenefits({ecobensEnabled, aggregateData}) {
      if (ecobensEnabled) {
        const benefits = [
          {
            title: 'Total Tree Benefit',
            icon: 'benefits.png',
            field: 'overall_value_usd',
          },
          {
            title: 'Stormwater Benefit',
            icon: 'water.png',
            field: 'hydro_runoff_avoided_value_usd',
          },
          {
            title: 'Air Quality Benefit',
            icon: 'air.png',
            field: 'overall_pollution_value_usd',
          },
          {
            title: 'Carbon Benefit',
            icon: 'co2.png',
            field: 'overall_carbon_value_usd',
          },
        ];
        benefits.forEach(benefit => {
          benefit.amount = Utils.formatAmount(
            aggregateData.ecobens[benefit.field],
            benefit.field,
            this.config
          );
        });

        // assign the whole thing instead of modifying elements so the child picks up and re-renders
        this.benefits = benefits;
      }

      this.ecoBenefitsEnabled = ecobensEnabled;
    },
  },
};
</script>
