import {Store} from '@/shared';
import {Repository} from '@/canopy-map';

class MapConfig {
  constructor(mapSet) {
    this._mapSet = mapSet;
  }

  onLoad() {
    return new Promise(resolve => {
      const canopyMap = this._canopyMap();
      if (canopyMap) return resolve(canopyMap);

      // could also watch for the mutation/action, might need different event
      const unwatch = Store.watch(
        state => state[this._mapSet.key()].canopyMap.config,
        () => {
          const canopyMap = this._canopyMap();

          if (canopyMap) {
            unwatch();
            resolve(canopyMap);
          }
        }
      );
    });
  }

  _canopyMap() {
    return Repository.fetch(this._mapSet);
  }
}

export default MapConfig;
