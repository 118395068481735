// encode map-level business rules here
// what would have to get re-written if we switched out mapbox?
class CanopyMap {
  constructor(
    {
      defaultZoom,
      defaultCenter,
      geocoderCountries,
      rasterToggledOn,
      currentLayerId,
      vectorLayerTileUrl,
    },
    vectorLayers,
    rasterLayer,
    currentSymbologyAttribute
  ) {
    this._defaultZoom = defaultZoom;
    this._defaultCenter = defaultCenter;
    this._geocoderCountries = geocoderCountries;
    this._rasterToggledOn = rasterToggledOn;

    this._vectorLayerTileUrl = vectorLayerTileUrl;

    this._vectorLayers = vectorLayers;
    this._rasterLayer = rasterLayer;
    this._currentSymbologyAttribute = currentSymbologyAttribute;

    // this doesn't account for raster layer being the currentLayer
    this._currentLayer =
      vectorLayers.find(layer => layer.id() === currentLayerId) || // this._rasterLayer ||
      this.defaultLayer();
  }

  defaultLayer() {
    return this._vectorLayers[0];
  }

  toggleSource() {
    if (this._rasterToggledOn) {
      this.setCurrentLayer(this.defaultLayer());
    } else {
      this.setCurrentLayer(this._rasterLayer);
    }
  }

  setCurrentLayer(layer) {
    this._currentLayer = layer;
  }

  // this isn't used except when retrieving vectorLayer
  currentLayer() {
    return this._currentLayer;
  }

  setSymbologyAttribute(field) {
    this._currentSymbologyAttribute = field;
  }

  currentSymbologyAttribute() {
    return this._currentSymbologyAttribute;
  }

  vectorLayers() {
    return this._vectorLayers;
  }

  vectorLayerTileUrl() {
    return this._vectorLayerTileUrl;
  }

  rasterLayer() {
    return this._rasterLayer;
  }

  // unused right now
  // rasterToggledOn() {
  //   return this._rasterToggledOn;
  // }

  defaultZoom() {
    return this._defaultZoom;
  }

  defaultCenter() {
    return this._defaultCenter;
  }

  geocoderCountries() {
    return this._geocoderCountries;
  }
}

export default CanopyMap;
