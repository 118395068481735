import FeatureMapboxRepository from './feature-mapbox-repository';
import Mapbox from '../interface/mapbox';
import {Store, Variables} from '@/shared';

let map, renderedMarker;

// TODO: move Mapbox specific functionality into infra/mapbox.js
class ParksMapAdapter {
  constructor(
    mapName,
    secondMapName,
    mapSetKey,
    baseMap = Mapbox.getMap(), // temp use of getMap()
    featureRepo = FeatureMapboxRepository
  ) {
    map = baseMap;
    this._mapSetKey = mapSetKey;
    // TODO: can get map names and mapSetkey from mapSet, maybe just pass that in
    this._featureRepo = new featureRepo(map, mapName, secondMapName, mapSetKey);
  }

  selectLayer(layer) {
    // static since state is managed by Store now instead of aggregates...
    Mapbox.setLayer(layer); // should be ParksMapAdapter for this eventually

    this._removeMarker(); // these are core functionality things, not just a "layer being added in mapbox"
    if (layer.polygonGeometry()) this._updateHighlight();
  }

  addFeatureClickHandler() {
    map.onClick(event => {
      this._featureRepo
        .getNearestFeature(event.point, event.lngLat)
        .then(feature => {
          // this code could be moved up a layer potentially? would be nice to have some abstracted understanding of what is going on here in the application layer
          Store.dispatch(`${this._mapSetKey}/parksMap/selectFeature`, feature); // this file is infra or app layer, and here depends on interface/ui layer code
        });
    });
  }

  selectFeature(feature, layer) {
    if (layer.pointGeometry()) {
      this._updateMarker(feature);
    } else if (layer.polygonGeometry()) {
      this._updateHighlight(feature);
    }
  }

  _updateMarker(feature) {
    if (feature) {
      this._removeMarker();

      renderedMarker = map.addMarker(feature.coordinates(), {
        color: Variables.timberGreen,
      });
    } else {
      this._removeMarker();
    }
  }

  _removeMarker() {
    if (renderedMarker) {
      renderedMarker.remove(); // mapbox api
      renderedMarker = null;
    }
  }

  _updateHighlight(feature) {
    const pid = feature ? feature.pid() : '';
    map.map.setFilter('feature-highlighted', ['in', 'pid', pid]); // mapbox api
  }
}

export default ParksMapAdapter;
