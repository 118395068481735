import {Store} from '@/shared';
import WorkRecord from '../../domain/work-record';

const WorkRecordVuexRepository = {
  getAllFromCurrentFeature(mapSet) {
    const workRecordsData =
      Store.state[mapSet.key()].parksMap.currentFeatureWorkRecords;

    return workRecordsData.map(data => new WorkRecord(data));
  },
};

export default WorkRecordVuexRepository;
