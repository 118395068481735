<template>
  <div class="work-container">
    <v-dialog
      v-model="showWorkDialog"
      content-class="bg-white pa-4"
      attach=".work-container"
      max-width="700px"
      :fullscreen="mobile">
      <v-row no-gutters>
        <v-col cols="10" sm="4">
          <DateFilter />
        </v-col>

        <v-col cols="2" sm="8" class="d-flex justify-end">
          <v-icon @click="close" large class="mt-n8 mb-n4"> mdi-close </v-icon>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <WorkRecord
            v-for="(model, index) in workRecords"
            :key="index"
            :model="model"
            :layer="currentLayer" />
          <p v-if="workRecords && !workRecords.length">
            No work records matching this timeframe filter.
          </p>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import DateFilter from './date-filter';
import {Utils} from '@/shared';
import WorkRecord from './work-record';
import {UseCases} from '@/parks-map';

export default {
  components: {DateFilter, WorkRecord},
  inject: ['mapSetKey', 'mapSet'],
  data: () => ({
    showWorkDialog: false,
  }),
  computed: {
    mobile() {
      return Utils.isMobile();
    },
    showWork() {
      return UseCases.getShowWork(this.mapSet);
    },
    workRecords() {
      return UseCases.getFilteredWorkRecords(this.mapSet);
    },
    currentLayer() {
      return this.$store.getters[`${this.mapSetKey}/parksMap/currentLayer`];
    },
  },
  watch: {
    showWork: {
      handler(showWork) {
        this.showWorkDialog = showWork;
      },
    },
    showWorkDialog: {
      // km 3/1/22: this could get triggered with false if a user on desktop presses ESC
      handler(showWorkDialog) {
        if (!showWorkDialog && this.showWork) this.close();
      },
    },
  },
  methods: {
    close() {
      UseCases.toggleWork(this.mapSetKey);
    },
  },
};
</script>

<!-- km 2/9/22: can't be scoped because of how v-dialog attaches to DOM separate from the Vue-rendered component -->
<style lang="scss">
.work-container .bg-white {
  background: white;
}
</style>
