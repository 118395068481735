import * as _ from 'lodash';
import {CustomControl, Mapbox} from '@/map';
import {Cypress, Utils} from '@/shared';
import inferno from 'scale-color-perceptual/inferno';
import nearestPoint from '@turf/nearest-point';
import {point, featureCollection} from '@turf/helpers';

const TREE_TILES_BASE_URL = `${Utils.getEnv('VUE_APP_BASE_URL')}/customers`;

const TREES_LAYER_ID = 't3p-trees';
const TREES_SOURCE_ID = 'trees-tiles';
const TREE_PLOTTER_TREES_LAYER_NAME = 'trees';
const MAP_STRING_NAME = 'tree-map';

let map;
let selectedSymbology;
let selectedSymbologyCategories;

class TreeMapMapbox {
  // km 6/22/21: this is only rendered after config is loaded so just pass it
  // through instead of customer like vue components
  render({config, treeClick, treeClickMarker, embedded}) {
    // km: duplicated in legend-symbology.vue, maybe extract
    selectedSymbology = config.fields[0].name;
    selectedSymbologyCategories = config.fields[0].values.map(
      category => category.value
    );

    map = new Mapbox({
      container: MAP_STRING_NAME,
      hash: embedded ? false : MAP_STRING_NAME,
      geocoderCountries: config.map.geocoderCountries,
      zoom: config.map.defaultZoom,
      center: config.map.defaultCenter,
      embedded,
    });

    // ap: these need to be set after each render, otherwise we lose click functionality
    map.addHoverCursorChange(TREES_LAYER_ID);
    this._addTreeDetailHandler(treeClick, treeClickMarker);

    // km: things to do after map has loaded and style change when toggling satellite layer
    map.onLoadAndStyleChange(() => {
      // km: could do these after map.setStyle in the show layer functions
      this._addTreesSource(config);
      this._addTreesLayer();

      Cypress.exposeMapFeaturesLoaded(
        map.map,
        TREES_LAYER_ID,
        'treeMapFeaturesLoaded'
      ); // for testing only
    });
  }

  getMap() {
    // temp until new TreeMap class is setup
    return map;
  }

  addCustomControl(component, position) {
    map.addControl(new CustomControl(component), position);
  }

  setSymbology(field, categories) {
    selectedSymbology = field;
    selectedSymbologyCategories = categories;

    const expressions = categories
      .map((category, index) => {
        return [category, inferno(1 - index / categories.length)];
      })
      .flat();

    // map.map.U.setCircleColor(layer: LayerRef, value: any) // if using mapbox-gl-utils
    map.map.setPaintProperty(TREES_LAYER_ID, 'circle-color', [
      'match',
      ['get', field],
      ...expressions,
      inferno(0),
    ]);
  }

  _addTreesSource({customer, site}) {
    map.addSource(TREES_SOURCE_ID, {
      type: 'vector',
      maxzoom: 15, // ap 12/8/21: Only request tiles up to z15, overzoom after that
      // km 7/2/21: maybe extract url building to config.js, it's very similar there
      tiles: [
        `${TREE_TILES_BASE_URL}/${customer}/${
          site ? `${site}/` : ''
        }trees/{z}/{x}/{y}.pbf`,
      ],
    });
  }

  _addTreesLayer() {
    let colorScale = selectedSymbologyCategories
      .map((id, i) => [id, inferno(1 - i / selectedSymbologyCategories.length)])
      .flat();

    colorScale.push(inferno(0)); // km: add other color (black for inferno)

    if (map.map.getStyle().layers.find(layer => layer.id === TREES_LAYER_ID))
      return;

    map.addLayer({
      id: TREES_LAYER_ID,
      type: 'circle',
      source: TREES_SOURCE_ID, // km: this has to correspond to the source above
      'source-layer': TREE_PLOTTER_TREES_LAYER_NAME, // km: this is the actual layer name in the file (as it is set in TreePlotter)
      paint: {
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          5,
          1, // zoom is 5 (or less) -> circle radius will be 1px
          12,
          3,
          17,
          4,
          20,
          5, // zoom is 20 (or greater)) -> circle radius will be 5px
        ],
        'circle-color': ['match', ['get', selectedSymbology], ...colorScale],
      },
    });
  }

  _addTreeDetailHandler(handler, treeClickMarker) {
    let treeDetailMarker;

    map.onClick(event => {
      const tree = this._getNearestTree(event);

      if (tree) {
        if (treeDetailMarker) treeDetailMarker.remove();
        treeDetailMarker = map.addMarker(tree.geometry.coordinates, {
          element: treeClickMarker,
          anchor: 'bottom',
        });
        handler(tree);
      } else if (treeDetailMarker) {
        treeDetailMarker.remove(); // remove/delete variable
        treeDetailMarker = null;
        handler(null);
      }
    });
  }

  _getNearestTree(event) {
    const {
      point: {x, y},
    } = event;
    const bboxLength = 25;

    const features = map.map.queryRenderedFeatures(
      [
        [x - bboxLength / 2, y - bboxLength / 2],
        [x + bboxLength / 2, y + bboxLength / 2],
      ],
      {layers: [TREES_LAYER_ID]}
    );

    return this._nearestFeature(event.lngLat, features);
  }

  _nearestFeature({lng, lat}, features) {
    if (features.length === 1) return features[0];

    const target = point([lng, lat]);

    if (features.length) {
      const nearestTurfFeature = nearestPoint(
        target,
        featureCollection(features)
      );
      return features.find(({geometry}) =>
        _.isEqual(geometry.coordinates, nearestTurfFeature.geometry.coordinates)
      );
    }
  }
}

const singleton = new TreeMapMapbox();
Object.freeze(singleton); // km 1/1/20: this is because we need all references
// to the map to only be this one instance and this class provides the interface

export default singleton;
