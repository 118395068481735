import {CustomControl} from '@/map';
import Mapbox from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {Utils} from '@/shared';
// import U from 'mapbox-gl-utils';

Mapbox.accessToken = Utils.getEnv('VUE_APP_MAPBOX_ACCESS_TOKEN');

// Mapbox.Map or something else
// wrap this around old interface (or vice versa, have wrapped in old ParksMapMapbox)
const SATELLITE_STYLE_URL = 'mapbox://styles/mapbox/satellite-streets-v11';

class MapboxBaseMap {
  // km: temporary alternative to calling render until tree map is ported to new architecture
  constructor(map) {
    this._map = map;
  }

  render({container, hash}) {
    this._map = new Mapbox.Map({
      container,
      hash,
      style: Utils.getEnv('VUE_APP_MAPBOX_STYLE'), //'mapbox://styles/mapbox/streets-v11',//'mapbox://styles/mapbox/satellite-streets-v11'
      minzoom: 0,
      maxzoom: 20,
      attributionControl: false, // attribution added with customizations below
    });
  }

  updateZoomAndCenter({zoom, center}) {
    this._map.setZoom(zoom);
    this._map.setCenter(center);
  }

  addGeocoderControl({countries, proximity}) {
    this._map.addControl(this._buildGeocoder(countries, proximity), 'top-left');
  }

  addNavigationControl() {
    this._map.addControl(
      new Mapbox.NavigationControl({showCompass: false}),
      'top-left'
    );
  }

  addGeolocateControl() {
    this._map.addControl(new Mapbox.GeolocateControl(), 'top-left');
  }

  addAttributionControl() {
    this._map.addControl(
      new Mapbox.AttributionControl({compact: true}),
      'bottom-right'
    );
  }

  // km 9/17/21: this is a temporary workaround for desktop (hosted) CEM maps
  //   to deal with scrolling down the page and not being able to scroll past
  //   the map because scrollZoom is enabled by default.
  disableScrollZoom() {
    this._map.scrollZoom.disable(); // km: temporary solution to scrolling past map on desktop, until we move the content sections and implement tabs or something infoatplanitgeo.atlassian.net/browse/TP2-50
  }

  // km: The mobile embedded CEM maps are embedded in customer pages we can't control and must allow
  //   scrolling past the maps, so dragPan (one finger touch) is being disabled
  //   here for those, and two finger touch is being set up to allow zooming.
  setupMobileDragWorkarounds() {
    this._map.dragPan.disable(); // km: solution to scrolling/dragging map causing map pan
    this._map.touchPitch.disable(); // km: disable two finger touch which pitches zoom by default

    this._map.on('touchstart', ({originalEvent}) => {
      // km: from https://github.com/mapbox/mapbox-gl-js/issues/2618#issuecomment-607260196
      if (this._isTwoFingerTouchEvent(originalEvent)) {
        this._map.dragPan.enable();
      } else {
        this._map.dragPan.disable();
      }
    });
  }

  cursorAsPointerOnHover(layerId) {
    this._map.on('mouseenter', layerId, () => {
      this._map.getCanvas().style.cursor = 'pointer';
    });

    this._map.on('mouseleave', layerId, () => {
      this._map.getCanvas().style.cursor = '';
    });
  }

  onLoadAndStyleChange(handler) {
    // km: style.load isn't part of the public api, but styledata doesn't work either as referenced in https://stackoverflow.com/a/60514976/2009660
    this._map.on('style.load', handler);
  }

  // layerId can also be handler if it isn't included (just 1 arg)
  onClick(layerId, handler) {
    this._map.on('click', layerId, handler);
  }

  addCustomControl(component, position) {
    this._map.addControl(new CustomControl(component), position);
  }

  toggleSatelliteLayer() {
    if (this._isSatellite()) {
      this._showDefaultLayer();
    } else {
      this._showSatelliteLayer();
    }
  }

  _isSatellite() {
    const style = this._map.getStyle();
    return style.metadata['mapbox:origin'] === 'satellite-streets-v11';
  }

  _showSatelliteLayer() {
    if (this._isSatellite()) return;

    this._map.setStyle(SATELLITE_STYLE_URL);
  }

  _showDefaultLayer() {
    if (!this._isSatellite()) return;

    this._map.setStyle(Utils.getEnv('VUE_APP_MAPBOX_STYLE'));
  }

  _buildGeocoder(countries, proximity) {
    return new MapboxGeocoder({
      accessToken: Mapbox.accessToken,
      mapboxgl: Mapbox,
      marker: false, // km: might want this true
      collapsed: true,
      countries,
      proximity, // km: prioritize searches closer to this, possible?
      zoom: 15,
      flyTo: {
        zoom: 15,
      },
    });
  }

  _isTwoFingerTouchEvent(event) {
    return event && 'touches' in event && event.touches.length > 1;
  }
}

export default MapboxBaseMap;
