<template>
  <div class="fill-height">
    <Map
      store-module="canopyMap"
      container-id="canopy-map"
      :hideOverlay="rasterToggledOn"
      :setupMap="setupMap">
      <MapOverlay :currentLayer="currentLayer" :polygon="polygon" />
    </Map>
  </div>
</template>

<script>
import {Interactions} from '../index';
import Map from '@/map';
import MapOverlay from './details-overlay/map-overlay';

export default {
  name: 'CanopyMap',
  components: {Map, MapOverlay},
  inject: ['mapSet'],
  provide() {
    return {
      storeModule: this.$store.state[this.mapSet.key()].canopyMap,
      moduleKey: 'canopyMap',
    };
  },
  data: () => ({
    polygon: null,
  }),
  computed: {
    currentLayer() {
      return Interactions.getCurrentLayer(this.mapSet);
    },
    rasterToggledOn() {
      // is this truthy check for this.storeModule necessary?
      return this.storeModule && this.storeModule.rasterToggledOn;
    },
  },
  // not sure if mobile vs smallScreen definition has been updated to be the same
  //   for CanopyMap as it is for other maps, especially ParksMap
  // definition brought from LayerDetails/AggregateDetails
  // computed: {
  //   mobile() {
  //     // embedded will not be special cased here due to the
  //     // desire that mobile functionality apply to embedded on mobile
  //     return this.$vuetify.breakpoint.mobile; // && !this.embedded;
  //   },
  // },
  methods: {
    // primary entrypoint for CanopyMap component
    // this has to be passed to <Map> because that is where the mapbox rendering
    //   is happening and it needs to wait until that component is attached
    //   (component's mounted() gets called) before attaching to the DOM
    setupMap() {
      Interactions.renderMap(this, this.mapSet, this._showFeatureDetails);
    },
    _showFeatureDetails(polygon) {
      this.polygon = polygon;
    },
  },
};
</script>
