<template>
  <DetailsOverlayCard>
    <template v-slot:title>Geography Details</template>
    <template v-if="currentLayer" v-slot:default>
      <div class="pb-1 pt-0">
        <b>{{ currentLayer.name() }}: </b>
        {{
          polygon.properties[currentLayer.aliasFieldName()]
            ? polygon.properties[currentLayer.aliasFieldName()]
            : 'Unknown'
        }}
      </div>
      <div
        v-for="(f, idx) in currentLayer.fields()"
        :key="idx"
        class="pb-1 pt-0">
        <b>{{ f.alias() }}: </b>
        {{
          typeof polygon.properties[f.name()] == 'number'
            ? Math.round(polygon.properties[f.name()]).toLocaleString() +
              (f.appendTo() ? f.appendTo() : '')
            : polygon.properties[f.name()]
        }}
      </div>
    </template>
  </DetailsOverlayCard>
</template>

<script>
import {DetailsOverlayCard} from '@/map';

export default {
  props: ['polygon', 'currentLayer'],
  components: {DetailsOverlayCard},
};
</script>
