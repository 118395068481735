<template>
  <!-- having mapbox classes here set based on mobile is a little concerning
       because it's easy to forget there are 2 ways to use this and that these
       are added for desktop -->
  <div
    class="legend-symbology"
    v-bind:class="{
      mobile,
      'mapboxgl-ctrl-group': !mobile,
      'mapboxgl-ctrl': !mobile,
      'buffer-overlay': bufferOverlay,
    }">
    <v-select
      :items="symbologiesSelectOptions"
      v-model="symbologySelectedValue"
      dense
      flat
      solo
      attach
      background-color="none"
      @change="symbologySelectChange"
      class="symbology font-weight-bold"></v-select>
    <v-switch
      v-show="loaded && symbologySelected.topFive.length"
      v-model="topFive"
      @change="topFiveSpeciesChange"
      label="Show top 5"
      class="mb-n1 mt-n2 ml-3 topFive"
      dense></v-switch>
    <div class="px-3 pb-2 symbology-category" v-bind:class="{mobile: mobile}">
      <div v-for="(category, index) in categories" :key="category.value">
        <span
          class="color"
          :style="{
            'background-color': colors(1 - index / categories.length),
          }"></span>
        <!-- categories needs to be here for this to update... maybe? -->
        <span>{{ category.alias }}</span>
      </div>
      <div>
        <span class="color" :style="{'background-color': colors(0)}"></span>
        <span>Other</span>
      </div>
    </div>
  </div>
</template>

<script>
import inferno from 'scale-color-perceptual/inferno';
import Mapbox from './mapbox';

export default {
  inject: ['storeModule', 'embedded'],
  data: () => ({
    symbologies: [],
    symbologySelected: null,
    symbologySelectedValue: null,
    symbologiesSelectOptions: [],
    fields: [],
    loaded: false,
    topFive: false,
    categories: [],
    colors: inferno,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile && !this.embedded;
    },
    bufferOverlay() {
      return (
        this.storeModule.overlayExpanded &&
        !(this.mobile || this.$vuetify.breakpoint.mobile)
      );
    },
  },
  created() {
    // km 6/22/21: this is only rendered after config is loaded in TreeMap so
    // just pass it through instead of customer like other vue components
    this.symbologies = this.storeModule.config.fields.filter(
      field => field.legendItem
    );

    this.symbologiesSelectOptions = this.symbologies.map(field => {
      return {
        text: field.alias,
        value: field.name,
      };
    });

    this.setupCategories(this.symbologies[0].name);
    this.loaded = true;
  },
  methods: {
    symbologySelectChange(newSymbology) {
      this.setupCategories(newSymbology);

      const categoryPids = this.categories.map(category => category.value);
      Mapbox.setSymbology(newSymbology, categoryPids); // can't do this on initial load because it calls map.setPaintProperty before map is drawn
    },
    topFiveSpeciesChange() {
      this.symbologySelectChange(this.symbologySelectedValue); // just run it because it will check topFive
    },
    setupCategories(symbologyName) {
      this.symbologySelected = this.symbologies.find(symbology => {
        return symbologyName === symbology.name;
      });

      this.symbologySelectedValue = this.symbologySelected.name;

      if (this.topFive && this.symbologySelected.topFive.length) {
        this.categories = this.symbologySelected.topFive;
      } else {
        this.categories = this.symbologySelected.values; // if for topFive, preserve toggle when symbology changes?
      }
    },
  },
};
</script>

<style scoped lang="scss">
.legend-symbology {
  z-index: 2;

  &.buffer-overlay {
    // this could be a variable set somewhere to the width of the overlay
    margin-right: 320px;
  }
}

.topFive {
  height: 36px;
}
.v-label {
  font-size: 14px;
}
.color {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}
.symbology {
  height: 40px;
}
.symbology-category {
  &:not(.mobile) {
    max-height: calc(
      475px - 40px - 36px
    ); // 475px from .legend-symbology - 40px from .symbology - 36px from v-switch
    overflow: scroll;
  }
}
</style>
