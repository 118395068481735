<template>
  <div>
    <div v-if="!polygon">
      <CanopyLayerSelect :currentLayer="currentLayer" />
      <SymbologyAttributeSelect />
    </div>

    <PolygonDetails v-else :polygon="polygon" :currentLayer="currentLayer" />
    <v-divider class="my-2"></v-divider>
    <Histogram :currentLayer="currentLayer" :polygon="polygon" />
  </div>
</template>

<script>
import CanopyLayerSelect from '../map-controls/canopy-layer-select';
import Histogram from './histogram';
import PolygonDetails from './polygon-details';
import SymbologyAttributeSelect from '../map-controls/symbology-attribute-select';

export default {
  components: {
    CanopyLayerSelect,
    Histogram,
    PolygonDetails,
    SymbologyAttributeSelect,
  },
  props: {
    currentLayer: {type: Object},
    polygon: {type: Object},
  },
};
</script>
