import {Config, Store} from '@/shared';
import {Feature} from '@/map';
import nearestPoint from '@turf/nearest-point';
import {WorkRecord} from '@/parks-map';
import {point, featureCollection} from '@turf/helpers';
import * as _ from 'lodash';

class FeatureMapboxRepository {
  constructor(map, mapName, secondMapName, mapSetKey) {
    this._map = map;
    this._mapName = mapName;
    this._secondMapName = secondMapName;
    this._mapSetKey = mapSetKey;
  }

  async getNearestFeature(clickPoint, clickCoordinates) {
    let mapFeature;

    if (this._layer().pointGeometry()) {
      const features = this._getLayerFeaturesInBbox(clickPoint);

      if (!features.length) return;

      mapFeature = this._findNearestFeature(clickCoordinates, features);
    } else if (this._layer().polygonGeometry()) {
      // no need to support proximity click for polygons (assuming polygons are large enough to easily click inside them)
      mapFeature = this._getLayerFeatureOnPoint(clickPoint);

      if (!mapFeature) return;
    }

    const featureData = await this._translatePropertiesAndAssocations(
      mapFeature
    );

    return new Feature(featureData);
  }

  _layer() {
    return Store.getters[`${this._mapSetKey}/parksMap/currentLayer`];
  }

  _getLayerFeaturesInBbox(mapPoint) {
    const {x, y} = mapPoint;
    const bboxLength = 25;

    // mapbox code in here that should be extracted into mapbox only code
    return this._map.map.queryRenderedFeatures(
      [
        [x - bboxLength / 2, y - bboxLength / 2],
        [x + bboxLength / 2, y + bboxLength / 2],
      ],
      {layers: [this._layer().id()]}
    );
  }

  _getLayerFeatureOnPoint(mapPoint) {
    const features = this._map.map.queryRenderedFeatures(mapPoint, {
      layers: [this._layer().id()],
    });

    return features && features[0];
  }

  _findNearestFeature({lng, lat}, features) {
    if (features.length === 1) return features[0];

    const target = point([lng, lat]);

    if (features.length) {
      const nearestTurfFeature = nearestPoint(
        target,
        featureCollection(features)
      );
      return features.find(({geometry}) =>
        _.isEqual(geometry.coordinates, nearestTurfFeature.geometry.coordinates)
      );
    }
  }

  async _translatePropertiesAndAssocations(mapFeature) {
    const {pid} = mapFeature.properties;
    const {coordinates} = mapFeature.geometry;
    const properties = await this._fetchFeatureProperties(pid);
    const workRecords = this._buildWorkRecords(properties.work_records);

    return {pid, coordinates, properties, layer: this._layer(), workRecords};
  }

  _fetchFeatureProperties(pid) {
    return Config.fetchLayerData(
      this._mapName,
      this._secondMapName,
      this._layer().id()
    ).then(layerData => layerData[pid.toString()]); // keys are strings
  }

  _buildWorkRecords(workRecordsData) {
    if (!workRecordsData) return;

    return workRecordsData.map(data => {
      // TODO: add this to mapper: mapboxToDomain() or persistenceToDomain()
      return new WorkRecord.Entity({
        // excluding item_id here
        pid: data.pid,
        work: data.work,
        status: data.work_status,
        dueDate: data.work_due_date,
        dateCompleted: data.date_completed,
        comments: data.comments,
        photos: data.photos,
        layer: this._layer(),
      });
    });
  }
}

export default FeatureMapboxRepository;
