<template>
  <DetailsOverlayCard>
    <template v-slot:title>{{ feature.layerName() }} Details</template>

    <!-- copy pasta from TreeDetails.vue -->
    <div class="py-3">
      <!-- maybe do without the cols and do more like Polygon Details, also maybe extract all 3 -->
      <v-row v-for="(value, label) in feature.properties()" :key="label">
        <v-col cols="5" class="font-weight-bold py-0">{{ label }}:</v-col>
        <v-col cols="7" class="py-0">{{ value }}</v-col>
      </v-row>
    </div>
    <!-- end copy pasta -->

    <v-row v-if="feature.workEnabled()" class="mt-1">
      <v-col>
        <v-btn
          @click.prevent="showWork"
          :disabled="feature.noWork()"
          color="primary"
          class="v-btn--flat float-right mr-2"
          href="#"
          height="48px">
          View Work <v-icon class="ml-2">mdi-wrench</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </DetailsOverlayCard>
</template>

<script>
import {DetailsOverlayCard} from '@/map';
import {UseCases} from '@/parks-map';

export default {
  components: {DetailsOverlayCard},
  inject: ['mapSet', 'mapSetKey'],
  computed: {
    feature() {
      return UseCases.getViewCurrentFeature(this.mapSet);
    },
  },
  methods: {
    showWork() {
      UseCases.toggleWork(this.mapSetKey);
    },
  },
};
</script>
