import {Config} from '@/shared';
import {Layer} from '@/map';
import {ParksMap} from '@/parks-map';

const ParksMapFactory = {
  // no arrow func so that this._buildLayer is available
  build(mapName, secondaryMapName) {
    return Config.loadParksMap(mapName, secondaryMapName).then(config => {
      const layers = config.layers.map(this.buildLayer);
      const parksMap = new ParksMap(layers);

      return parksMap;
    });
  },
  buildLayer(rawAttributes) {
    return new Layer(ParksMapFactory._layerAttributes(rawAttributes));
  },
  _layerAttributes(rawAttributes) {
    const attributes = {
      id: rawAttributes.name, // switching to mesh better with mapbox
      pid: rawAttributes.pid,
      name: rawAttributes.alias, // switching to be more intuitive within CEM
      geomType: rawAttributes.geomType, // string value is not capitalized, maybe capitalize to be like what mapbox uses
      fields: rawAttributes.fields,
      dataFile: rawAttributes.dataFile,
      tileUrl: rawAttributes.tileUrl, // only used by canopy map raster layer
      symbologyFieldName: rawAttributes.symbologyFieldName,
      enableWorkRecords: rawAttributes.enableWorkRecords, // only used by parks map
      aliasFieldName: rawAttributes.aliasFieldName, // only used by canopy map
      data: rawAttributes.data, // only used by canopy map
    };

    if (attributes.enableWorkRecords) {
      Object.assign(attributes, {
        workRecordFields: rawAttributes.workRecords.fields,
        workRecordFilters: rawAttributes.workRecords.filters,
      });
    }

    return attributes;
  },
};

export default ParksMapFactory;
