import {CanopyMap, RasterLayer} from '@/canopy-map';
import {Field, Layer} from '@/map';

const CanopyMapFactory = {
  build({
    attributes,
    symbologyAttributeAttributes,
    rasterLayerAttributes,
    vectorLayerAttributes,
  }) {
    let symbologyAttribute;

    const vectorLayers = vectorLayerAttributes.map(attributes => {
      // rename Layer to be VectorLayer? would impact ParksMap (and maybe TreeMap)
      return new Layer(attributes);
    });

    const rasterLayer = new RasterLayer(rasterLayerAttributes);

    if (symbologyAttributeAttributes) {
      symbologyAttribute = new Field(symbologyAttributeAttributes);
    }

    return new CanopyMap(
      attributes,
      vectorLayers,
      rasterLayer,
      symbologyAttribute
    );
  },
};

export default CanopyMapFactory;
