class ParksMap {
  constructor({layers, defaultVisibleLayer}) {
    this._layers = layers;
    this._currentLayer = this._defaultLayer(defaultVisibleLayer);
  }

  currentLayer() {
    return this._currentLayer;
  }

  _defaultLayer(defaultVisibleLayer) {
    return this._layers.find(layer => layer.pid() === defaultVisibleLayer);
  }
}

export default ParksMap;
