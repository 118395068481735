<template>
  <!-- side panel (bottom on mobile) -->
  <div
    class="details-overlay-container"
    v-bind:class="{mobile, expanded, embedded}">
    <!-- panel toggle (non-mobile) -->
    <div class="overlay-toggle" v-bind:class="{'d-none': mobile, expanded}">
      <v-btn
        @click="toggleOverlay"
        class="overlay-toggle-button pa-0"
        elevation="4"
        tile>
        <v-icon
          class="arrow"
          v-bind:class="{
            'arrow-left': !expanded && !mobile,
            'arrow-right': expanded && !mobile,
          }">
          mdi-triangle
        </v-icon>
      </v-btn>
    </div>
    <!-- slide out panel -->
    <!-- right keeps it on the right for non-mobile. Bottom puts drawer at the bottom for mobile only -->
    <v-navigation-drawer
      absolute
      right
      bottom
      hide-overlay
      v-model="expanded"
      :width="mobile ? '100%' : '310px'"
      class="side-panel justify-start mt-0"
      v-bind:class="{collapsed: !expanded, mobile}"
      v-click-outside="{closeConditional: () => false}">
      <!-- panel toggle (mobile) -->
      <div
        v-if="mobile"
        class="mobile-overlay-toggle"
        @click="toggleOverlay"
        v-bind:class="{expanded}">
        <div class="bar" elevation="4"></div>
      </div>
      <div class="details-overlay-contents-container">
        <!-- entrypoint slot -->
        <slot></slot>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {Utils} from '@/shared';

export default {
  inject: ['mapSetKey', 'storeModule', 'moduleKey'],
  data: () => ({
    expanded: true,
  }),
  computed: {
    embedded() {
      return this.$store.state.embedded;
    },
    mobile() {
      // embedded will not be special cased here due to the
      // desire that mobile functionality apply to embedded on mobile
      return Utils.isMobile() || this.$vuetify.breakpoint.mobile;
    },
    overlayExpanded() {
      return this.storeModule && this.storeModule.overlayExpanded;
    },
  },
  // km 3/14/22: this all is necessary because v-model for v-nagivation-drawer
  // has to be in data so it is mutable, as opposed computed (or prop)
  watch: {
    overlayExpanded: {
      handler(overlayExpanded) {
        this.expanded = overlayExpanded; // just copy this over if it ever changes
      },
    },
    expanded: {
      handler(expanded) {
        // this can change from v-model in v-navigation-drawer, but need to make
        // sure not to enter infinite loop from overlayExanded watch handler
        // retriggering this when store action is called
        if (expanded !== this.overlayExpanded) this.toggleOverlay();
      },
    },
  },
  methods: {
    toggleOverlay() {
      this.$store.dispatch(`${this.mapSetKey}/${this.moduleKey}/toggleOverlay`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.details-overlay-container {
  position: absolute;
  width: 100%;

  // this allows the visibility of the toggle in the navigation
  // drawer
  .v-navigation-drawer--close {
    visibility: visible !important;
  }

  &.mobile {
    &.expanded {
      height: 40%;
    }

    &:not(.expanded) {
      height: 0%;
    }

    &.embedded {
      bottom: 0; // no footer/tabs for embedded
    }

    &:not(.embedded) {
      bottom: 56px; // height of footer
      position: fixed;
    }

    z-index: 1; // needed so it appears over the map
  }

  &:not(.mobile) {
    height: 100%;
  }

  .side-panel {
    background-color: rgba($white, 0.9) !important;
    box-shadow: none;

    // nagivation drawer uses transform: translateX/translateY to
    // move the container off screen. This causes the occasional
    // bug of the entire view field being able to be moved since
    // the drawer has a width/height outside the viewfield. Capping
    // the overflow was sometimes disabling panning...

    &.mobile {
      max-height: 100%; // overriding v-navigation max-height of 50%
    }

    &.mobile.collapsed {
      height: 0px !important;

      // overriding transform: translateX(100%) for when on large screen mobile devices
      transform: translateY(100%) !important;
    }

    &:not(.mobile).collapsed {
      width: 0px !important;
    }

    &.mobile.v-navigation-drawer__border {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

.mobile-overlay-toggle {
  border-radius: 1em 1em 0 0;
  z-index: 1; // important to keep toggle visible over map
  right: 0;
  left: 0;
  position: absolute;
  padding: 10px 0;
  margin-top: -28px; // must be 2 * border-radius
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  transition-property: bottom, background-color, height;
  box-shadow: outset 0px 16px 24px 2px rgb(0 0 0 / 14%),
    0px 6px 30px 5px rgb(0 0 0 / 12%); // keep around?

  &.expanded {
    background-color: rgba($white, 0.9);
  }

  &:not(.expanded) {
    background-color: rgba($dark-blue, 1);
  }

  .bar {
    width: 3em;
    height: 0.5em;
    border-radius: 1em;
    margin: 0 auto;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.overlay-toggle {
  top: 0;
  z-index: 1; // important to keep toggle visible over map
  right: auto;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
  transition-property: right, width;

  // width of aside (default: 310px)
  &.expanded {
    right: 310px;
  }

  &:not(.expanded) {
    right: 0%;
  }

  .overlay-toggle-button {
    height: 48px !important;
    min-width: 23px !important;
    margin: 0 auto;
    margin-top: 20px;
    border-right: 1px solid #d4d4d4;

    .arrow {
      font-size: 10px;

      &.arrow-left {
        transform: rotate(-90deg) scaleY(0.6);
      }
      &.arrow-right {
        transform: rotate(90deg) scaleY(0.6);
      }
      &.arrow-top {
        transform: scaleY(0.6);
      }
      &.arrow-bottom {
        transform: rotate(-180deg) scaleY(0.6);
      }
    }
  }
}

.details-overlay-contents-container {
  height: 100%;
  overflow-y: scroll;
}
</style>
