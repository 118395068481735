<template>
  <div class="mapboxgl-ctrl-group mapboxgl-ctrl">
    <v-btn
      @click.prevent="toggleSource"
      :title="buttonTooltip"
      href="#"
      class="white px-0"
      height="29px"
      width="29px"
      min-width="29px">
      <v-icon>mdi-map</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {Interactions} from '@/canopy-map';

// TODO: have appearance of button change to reflect state change?
// TODO: extract button tooltip text and/or raster layer name to domain/presenter
export default {
  inject: ['mapSet'],

  computed: {
    buttonTooltip() {
      const rasterLayer = Interactions.getRasterLayer(this.mapSet);
      return `Toggle ${rasterLayer.name()} custom imagery layer`;
    },
  },

  methods: {
    toggleSource() {
      Interactions.toggleSource(this.mapSet);
    },
  },
};
</script>

<style scoped lang="scss">
i.mdi-layers {
  font-size: 20px;
}
</style>
