import Field from './field';

// const attributeNames = [
//   'id',
//   'pid',
//   'name',
//   'geomType',
//   'dataFile',
//   'fields',
//   'workRecordFields',
//   'workRecordFilters',
//   'symbologyFieldName',
//   'enableWorkRecords',
//   'aliasFieldName',
//   'data',
// ];

// would be nice to use these dynamic attributes to create getter functions too

class Layer {
  constructor({
    id,
    pid,
    name,
    geomType,
    dataFile,
    tileUrl,
    fields,
    workRecordFields,
    workRecordFilters,
    symbologyFieldName,
    enableWorkRecords,
    aliasFieldName,
    data,
  }) {
    this._id = id;
    this._pid = pid;
    this._name = name;
    this._geomType = geomType;
    this._dataFile = dataFile;
    this._tileUrl = tileUrl; // only used by canopy map raster layer
    this._fields = fields;
    this._workRecordFields = workRecordFields;
    this._workRecordFilters = workRecordFilters;
    this._symbologyFieldName = symbologyFieldName;
    this._enableWorkRecords = enableWorkRecords; // only used by parks map
    this._aliasFieldName = aliasFieldName; // only used by canopy map
    this._data = data; // only used by canopy map, maybe call chartData
  }

  // doing this instead of having two classes named RasterLayer in MapAdapter and checking instanceof
  isImageryType() {
    return false;
  }

  id() {
    return this._id;
  }

  pid() {
    return this._pid;
  }

  name() {
    return this._name;
  }

  geomType() {
    return this._geomType;
  }

  dataFile() {
    return this._dataFile;
  }

  tileUrl() {
    return this._tileUrl;
  }

  // changing this to return Array[Field] may have broken elsewhere
  fields() {
    return this._fields.map(fieldAttributes => new Field(fieldAttributes));
  }

  // TODO: return array of domain objects
  workRecordFields() {
    return this._workRecordFields;
  }

  workRecordFilters() {
    return this._workRecordFilters;
  }

  enableWorkRecords() {
    return this._enableWorkRecords;
  }

  pointGeometry() {
    return this.geomType() === 'point';
  }

  polygonGeometry() {
    return this.geomType() === 'MultiPolygon';
  }

  symbologyFieldName() {
    return this._symbologyFieldName;
  }

  symbologyField() {
    return this.fields().find(
      field => field.name() === this._symbologyFieldName // was using symbologyField/pid
    );
  }

  // only used by canopy map
  data() {
    return this._data;
  }

  aliasFieldName() {
    return this._aliasFieldName;
  }
}

export default Layer;
