<template>
  <div class="fill-height">
    <Map container-id="tree-map" :setup-map="setupMap">
      <AggregateDetails :tree="tree" />
    </Map>
  </div>
</template>

<script>
import AggregateDetails from './details-overlay/aggregate-details';
import TreeMarker from './tree-marker';
import Map, {MarketingLink, ToggleSatelliteButton} from '@/map';
import LegendSymbology from './legend-symbology';
import {Mapbox} from '../index';
import {Store, Utils} from '@/shared';
import Vue from 'vue';

export default {
  name: 'TreeMap',
  components: {AggregateDetails, Map},
  inject: ['mapSet', 'embedded'],
  data() {
    return {
      tree: null,
      treeFieldOptions: {},
    };
  },
  computed: {
    mobile() {
      return Utils.isMobile() || this.$vuetify.breakpoint.mobile;
    },
  },
  provide() {
    return {
      storeModule: this.$store.state[this.mapSet.key()].treeMap,
      moduleKey: 'treeMap',
    };
  },
  methods: {
    setupMap() {
      const stateSelector = state => state[this.mapSet.key()].treeMap.config;

      const watcherCallback = newConfig => {
        if (!Object.keys(newConfig).length) return;

        // Render the map with the new configuration
        Mapbox.render({
          config: Store.state[this.mapSet.key()].treeMap.config,
          treeClick: this._showTreeDetails,
          treeClickMarker: this._treeMarker(),
          embedded: this.embedded,
        });

        // Add custom controls to the map
        Mapbox.addCustomControl(
          new Vue({...ToggleSatelliteButton, parent: this}).$mount()
        );

        Mapbox.addCustomControl(
          new Vue({
            ...MarketingLink,
            parent: this,
          }).$mount(),
          this.mobile ? 'top-right' : 'bottom-left'
        );

        if (!this.mobile)
          Mapbox.addCustomControl(
            new Vue({
              ...LegendSymbology,
              parent: this,
              propsData: {
                config: Store.state[this.mapSet.key()].treeMap.config,
              },
            }).$mount(),
            'bottom-right'
          );

        // Unwatch after the first change
        unwatch();
      };

      // Set up the watcher
      const unwatch = Store.watch(stateSelector, watcherCallback);

      // Manually call the watcher callback with the current state
      const currentConfig = stateSelector(Store.state);
      watcherCallback(currentConfig);
    },
    _showTreeDetails(tree) {
      this.$data.tree = tree;
    },
    _treeMarker() {
      return new Vue({
        ...TreeMarker,
        parent: this, // this being parent component is important
      }).$mount().$el;
    },
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';

#tree-map {
  width: 100%;
  height: 600px;
  flex-grow: 1;
  z-index: 1;
  position: absolute;

  &.mobile {
    height: 100%;
  }
}

.flex-column-mobile {
  position: relative;
  height: 600px;

  &.mobile {
    height: 100%;
  }

  &.mobile-embedded {
    margin-top: 0px;
  }
}
</style>
