<template>
  <div>
    <DetailsOverlayCard>
      <template v-slot:title>Tree Details</template>
      <div class="py-3">
        <v-row v-for="(value, label) in treeTranslated" :key="label">
          <v-col cols="5" class="font-weight-bold py-0">{{ label }}:</v-col>
          <v-col cols="7" class="py-0">{{ value }}</v-col>
        </v-row>
        <v-row v-if="treeNotes">
          <v-col class="py-2">
            <span class="font-weight-bold">Notes:</span> {{ treeNotes }}
          </v-col>
        </v-row>
        <v-img
          v-for="(photo, index) in treePhotos"
          :key="index"
          class="mt-2"
          max-height="150"
          max-width="250"
          :src="photo">
        </v-img>
      </div>
    </DetailsOverlayCard>

    <EcoBenefits v-if="ecoBenefitsEnabled" :benefits="benefits" />
  </div>
</template>

<script>
import * as _ from 'lodash';
import {Utils} from '@/shared';
import {DetailsOverlayCard} from '@/map';
import EcoBenefits from './eco-benefits';

const formatPhotos = photos => {
  if (_.isNil(photos)) return;

  return JSON.parse(photos).map(photo => photo.url);
};

const formatIfDbhUnits = (property, value) => {
  if (_.isNil(value)) return '';

  if (property === 'dbh_exact') {
    return `${value} in`;
  } else if (property === 'dbh_cm') {
    return `${value} cm`;
  } else {
    return value;
  }
};

// maybe refactor the data translation in a separate file, this should be UI related in this file
const translate = (properties, fields) => {
  return _.transform(properties, (translated, reference, property) => {
    const field = _.find(fields, {name: property});
    const label = field.alias;

    if (_.isNil(label))
      // km: this gets rid of ecoben_carb_avoid from BTP which shouldn't be shown in tree fields, might just be a data issue
      return;

    if (['photos', 'notes', 'dbh'].includes(property))
      // km: dbh (dbh range) is only used for symbology, others have different formatting and are handled separate
      return;

    const lookup = _.find(fields, {name: property}).values;

    if (lookup.length) {
      translated[label] = _.find(lookup, {value: reference}).alias;
    } else {
      translated[label] = formatIfDbhUnits(property, properties[property]);
    }
  });
};

export default {
  components: {DetailsOverlayCard, EcoBenefits},
  props: ['tree'],
  inject: ['storeModule'],
  data() {
    return {
      treeTranslated: {},
      treePhotos: [],
      treeNotes: null,
      ecoBenefitsEnabled: false,
      benefits: [],
    };
  },
  watch: {
    tree: {
      immediate: true, // km 12/22/20: this makes sure this runs the first time TreeDetails is loaded
      handler({properties}) {
        // km 6/22/21: since this only loads on tree click, it's after config is loaded in TreeMap, so it's passed in
        this.treeTranslated = translate(
          properties,
          this.storeModule.config.fields
        );
        this.treePhotos = formatPhotos(properties.photos); // km 6/22/21: this has different formatting, so handle separate
        // km 6/22/21: notes has different formatting, so handle separate
        this.treeNotes = properties.notes;

        this._setupEcoBenefits(properties);
      },
    },
  },
  methods: {
    _setupEcoBenefits(properties) {
      const {config} = this.storeModule;

      if (config.ecobensEnabled) {
        const volumeAlias = config.isMetric ? ' (m³)' : ' (ft³)';
        const weightAlias = config.isMetric ? ' (kg)' : ' (lbs)';
        (this.benefits = [
          {
            title: 'Total Monetary Benefit',
            icon: 'benefits.png',
            field: 'overall_value_usd',
          },
          {
            title: 'Stormwater Monetary Benefit',
            icon: 'water.png',
            field: 'hydro_runoff_avoided_value_usd',
          },
          {
            title: 'Runoff Avoided' + volumeAlias,
            icon: 'water.png',
            field: 'hydro_runoff_avoided_cubic_feet',
          },
          {
            title: 'Air Quality Monetary Benefit',
            icon: 'air.png',
            field: 'overall_pollution_value_usd',
          },
          {
            title: 'Pollutants removed' + weightAlias,
            icon: 'air.png',
            field: 'overall_pollution_lbs',
          },
          {
            title: 'Carbon Monetary Benefit',
            icon: 'co2.png',
            field: 'overall_carbon_value_usd',
          },
          {
            title: 'Carbon Stored' + weightAlias,
            icon: 'co2.png',
            field: 'carbon_storage_lbs',
          },
          {
            title: 'Carbon Sequestered' + weightAlias,
            icon: 'co2.png',
            field: 'carbon_co2_sequestered_lbs',
          },
          {
            title: 'Carbon Dioxide Stored' + weightAlias,
            icon: 'co2.png',
            field: 'carbon_dioxide_storage_lbs',
          },
        ]),
          (this.benefits = this.benefits.map(benefit => {
            return _.merge(benefit, {
              amount: Utils.formatAmount(
                properties[benefit.field],
                benefit.field,
                config,
                true
              ),
            });
          }));
      }

      this.ecoBenefitsEnabled = config.ecobensEnabled;
    },
  },
};
</script>
