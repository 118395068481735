import App from './app';
import Router from '../application/router';
import titleMixin from '../infrastructure/title-mixin';
import Vue from 'vue';
import {Store, Vuetify} from '@/shared';
import './app.scss';

Vue.mixin(titleMixin);

new Vue({
  // keys have to be lowercase
  router: Router,
  store: Store,
  vuetify: Vuetify,
  render: h => h(App),
}).$mount('#app');

// commented out as reference for now in case...
// Cypress.exposeApp(app); // for testing only
