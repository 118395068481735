// this file is full of code that is only run for exposing things for Cypress integration testing
const Cypress = {
  // commented out as reference for now in case...
  // exposeApp(app) {
  //   // exposure app during testing
  //   // https://www.cypress.io/blog/2017/11/28/testing-vue-web-application-with-vuex-data-store-and-rest-backend/#Store-to-UI-and-server-API
  //   if (window.Cypress) {
  //     window.app = app;
  //   }
  // },

  // this function is only to allow cypress-wait-until for cypress tests
  // other mapbox map events weren't reliable for this case, maybe look into using 'idle' event
  exposeMapFeaturesLoaded(map, layerId, windowPropertyName) {
    if (!window.Cypress) return;

    let numberFeatures, checkRenderedFeatures;

    (checkRenderedFeatures = () => {
      // TODO: pass in Mapbox wrapper (i.e. /src/canopy-map/interface/mapbox/canopy-map.js)
      //   and have something on BaseMap handle the mapbox internal stuff
      // context: this was originally put in here because this is test-specific
      //   mapbox code, so it made sense to isolate, but passing in the wrapped
      //   mapbox.map instance is weird/hacky
      const features = map.queryRenderedFeatures({
        layers: [layerId],
      });

      // this can sometimes still result on finishing before everything is loaded, could have 3 variables to see if they all remain the same to improve
      if (features.length > 0 && features.length === numberFeatures) {
        window[windowPropertyName] = true;
        return;
      }

      numberFeatures = features.length;
      setTimeout(checkRenderedFeatures, 100);
    })();
  },
};

export default Cypress;
