<template>
  <v-select
    :items="filterOptions"
    :item-text="filterText"
    v-model="selected"
    label="Timeframe"
    class="pt-0"></v-select>
</template>

<script>
import {WorkRecord} from '@/parks-map';

export default {
  inject: ['mapSetKey'],
  data: () => ({
    selected: null,
  }),
  computed: {
    filterOptions() {
      return WorkRecord.DateFilter.options(this._currentLayer);
    },

    filterText() {
      return WorkRecord.DateFilter.WORK_RECORD_FILTER_TEXT;
    },

    _currentLayer() {
      return this.$store.getters[`${this.mapSetKey}/parksMap/currentLayer`];
    },
  },
  watch: {
    selected: {
      handler(selected) {
        this.$store.dispatch(
          `${this.mapSetKey}/parksMap/workRecordsDateFilter`,
          selected
        );
      },
    },
    _currentLayer: {
      immediate: true,
      handler(currentLayer) {
        this.selected =
          this.$store.getters[
            `${this.mapSetKey}/parksMap/workRecordsDateFilter`
          ] || WorkRecord.DateFilter.defaultValue(currentLayer);
      },
    },
  },
};
</script>
