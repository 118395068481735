<template>
  <div class="marketing-link-container mapboxgl-ctrl">
    <a
      href="https://planitgeo.com/treeplotter/?utm_source=cem-apps&utm_medium=cem-apps&utm_campaign=community-engagement-apps&utm_campaign=community-engagement-apps&utm_source=cem-apps&utm_medium=cem-apps&utm_content=community-engagement-apps"
      target="_blank">
      <v-img
        :src="`${baseUrl}/img/tree-plotter-map.png`"
        alt="TreePlotter marketing link"></v-img>
    </a>
  </div>
</template>

<script>
import {Utils} from '@/shared';

export default {
  name: 'MarketingLink',
  computed: {
    baseUrl() {
      return Utils.getEnv('VUE_APP_BASE_URL');
    },
  },
};
</script>

<style scoped lang="scss">
.marketing-link-container {
  background: transparent;
  box-shadow: none;
  right: 0;
  position: absolute;
}
</style>
