import {Field} from '@/map';
import {MapboxAdapter, Repository} from '@/canopy-map';
import MapService from './map-service';
import {Store, Utils} from '@/shared';
import ColorScaleService from './color-scale-service';
import MapConfig from '../infrastructure/map-config';

// could use a class/instance to abstract mapSet better
// alternatively Features/UseCases same/similar to Interactions
const CanopyMapInteractions = {
  renderMap(parentComponent, mapSet, tempShowFeatureDetailsHandler) {
    const {embedded} = Store.state;
    const mobile = Utils.isMobile();

    MapService.renderWithoutData(
      parentComponent,
      embedded,
      mobile,
      tempShowFeatureDetailsHandler // this is temporary until polygon/feature is run through store/repo
    );

    // wait for config load to render the rest
    new MapConfig(mapSet).onLoad().then(canopyMap => {
      MapService.addConfiguredLayers(canopyMap, parentComponent);
    });
  },

  getLayers(mapSet) {
    return Repository.getLayers(mapSet);
  },

  getCurrentLayer(mapSet) {
    return Repository.getCurrentLayer(mapSet);
  },

  getRasterLayer(mapSet) {
    return Repository.getRasterLayer(mapSet);
  },

  selectLayer(mapSet, layer) {
    const canopyMap = Repository.fetch(mapSet);
    canopyMap.setCurrentLayer(layer);

    Repository.saveCurrentLayer(mapSet.key(), canopyMap);

    MapboxAdapter.selectLayer(layer);
  },

  getSymbologyAttribute(mapSet) {
    // TODO: use repo
    //   could be canopyMap.getCurrentSymbologyAttribute()
    //     or
    //   SymbologyAttributeVuexRepository.getCurrent() followed by .save() in selectSymbologyAttribute
    const data = Store.state[mapSet.key()].canopyMap.symbologyAttribute;

    if (!data) return;

    return new Field(data);
  },

  toggleSource(mapSet) {
    const canopyMap = Repository.fetch(mapSet);
    // set symbologyAttribute to null? it sticks around after toggling this on and then off, which isn't necessarily bad
    // TODO: update currentLayer in store when this changes (though raster currentLayer isn't used for anything...)
    // unset selected feature/polygon here? sticks around after toggling on then off
    //   no easy way to change selected polygon here as it is not woven through Interactions/Store
    canopyMap.toggleSource();

    MapboxAdapter.selectLayer(canopyMap.currentLayer());

    Store.dispatch(`${mapSet.key()}/canopyMap/toggleSource`); // TODO: use repository
  },

  selectSymbologyAttribute(mapSet, symbologyAttribute) {
    const canopyMap = Repository.fetch(mapSet);

    canopyMap.setSymbologyAttribute(symbologyAttribute);

    // TODO: move to repo
    Store.dispatch(
      `${mapSet.key()}/canopyMap/selectSymbologyAttribute`,
      symbologyAttribute.toObject()
    );

    const colorScaleService = new ColorScaleService(
      canopyMap.currentLayer(),
      symbologyAttribute
    );

    // TODO: use canopyMap domain entity
    MapboxAdapter.updateSymbologyAttribute(
      symbologyAttribute.name(),
      colorScaleService.getColorSteps()
    );
  },
};

export default CanopyMapInteractions;
