<template>
  <DetailsOverlayCard>
    <template v-slot:title>{{ title }}</template>
    <template v-slot:subtitle><slot></slot></template>
    <v-list class="font-weight-bold pt-sm-1 pt-0">
      <v-list-item
        v-for="benefit in benefits"
        :key="benefit.title"
        class="px-0 pb-sm-2">
        <v-list-item-icon class="mr-2 mt-1 mb-0">
          <!-- need to have static path preceding dynamic filename for src -->
          <v-img
            :src="require(`./images/${benefit.icon}`)"
            max-height="30"
            max-width="30">
          </v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-0">
          <p class="primary--text line-height-normal">
            {{ benefit.title }}
          </p>
          <p class="mb-0 line-height-normal">
            {{ benefit.amount }}
          </p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </DetailsOverlayCard>
</template>

<script>
import {DetailsOverlayCard} from '@/map';

export default {
  props: {
    benefits: {type: Array},
    title: {type: String, default: 'Eco Benefits'},
  },
  components: {DetailsOverlayCard},
};
</script>

<style scoped lang="scss">
.v-list-item {
  min-height: 40px;
}

.line-height-normal {
  line-height: normal;
}
</style>
