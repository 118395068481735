<template>
  <!-- might want to turn off hide-on-scroll for embedded -->
  <!-- doesn't seem to be a reason to hide (via v-if) header on small (desktop) screens (by pulling in `|| this.$vuetify.breakpoint.mobile`) -->
  <v-app-bar v-if="!mobile" hide-on-scroll app flat color="white">
    <a
      href="https://planitgeo.com/treeplotter/?utm_source=cem-apps&utm_medium=cem-apps&utm_campaign=community-engagement-apps&utm_campaign=community-engagement-apps&utm_source=cem-apps&utm_medium=cem-apps&utm_content=community-engagement-apps"
      target="_blank">
      <v-img
        alt="TreePlotter Logo"
        contain
        transition="scale-transition"
        src="./images/tree-plotter.png"
        min-width="100"
        width="200" />
    </a>
  </v-app-bar>
</template>

<script>
import {Utils} from '@/shared';

export default {
  name: 'HeaderNav',
  computed: {
    mobile() {
      return Utils.isMobile();
    },
  },
};
</script>
