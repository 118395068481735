import {MapboxAdapter} from '@/canopy-map';

const MapService = {
  renderWithoutData(
    parentComponent,
    embedded,
    mobile,
    tempShowFeatureDetailsHandler
  ) {
    MapboxAdapter.setup({
      includeHash: !embedded,
      mobileDragWorkarounds: embedded && mobile,
    });

    MapboxAdapter.addMarketingLinkControl(parentComponent);

    MapboxAdapter.addFeatureClickHandler(tempShowFeatureDetailsHandler);
  },

  addConfiguredLayers(canopyMap, parentComponent) {
    MapboxAdapter.updateZoomAndCenter({
      zoom: canopyMap.defaultZoom(),
      center: canopyMap.defaultCenter(),
    });

    MapboxAdapter.addMapControls({
      countries: canopyMap.geocoderCountries(),
      proximity: canopyMap.defaultCenter(),
    });

    if (canopyMap.rasterLayer().visible())
      MapboxAdapter.addSourceToggleControl(parentComponent);

    MapboxAdapter.addVectorLayerImmediatelyAndOnLoad(
      canopyMap.defaultLayer(),
      canopyMap.vectorLayerTileUrl()
    );
  },
};

export default MapService;
