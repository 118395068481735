<template>
  <div v-if="symbology">
    <!-- && symbology.legendItem() add to v-if if using legendItem somehow -->
    <DetailsOverlayCard>
      <template v-slot:title>
        <div class="px-3">
          {{ symbology.alias() }}
        </div>
      </template>
      <div class="px-3 pb-2">
        <div v-for="(category, index) in categories" :key="category.value">
          <span
            class="color"
            :style="{
              'background-color': colors(1 - index / categories.length),
            }"></span>
          <span>{{ category.alias }}</span>
        </div>
        <div>
          <span class="color" :style="{'background-color': colors(0)}"></span>
          <span>Other</span>
        </div>
      </div>
    </DetailsOverlayCard>
  </div>
</template>

<script>
// lots of copy and past from tree-map/legend-symbology.vue, map extract common?
import {DetailsOverlayCard} from '@/map';
import inferno from 'scale-color-perceptual/inferno';

// TODO: extract shared legend-symbology component from this and tree-map legend-symbology
export default {
  components: {DetailsOverlayCard},
  inject: ['mapSetKey', 'storeModule', 'embedded'],
  data: () => ({
    colors: inferno,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile && !this.embedded;
    },
    symbology() {
      if (!this.storeModule || !this.storeModule.config) return;

      const currentLayer =
        this.$store.getters[`${this.mapSetKey}/parksMap/currentLayer`];

      return currentLayer && currentLayer.symbologyField();
    },
    categories() {
      return this.symbology.values();
    },
  },
};
</script>

<style scoped lang="scss">
.color {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}
</style>
