// km: ref https://medium.com/@Taha_Shashtari/the-easy-way-to-change-page-title-in-vue-6caf05006863

function getTitle(vm) {
  // use this by setting `title: 'Community Engagement Tree Map'` on a component
  let {title} = vm.$options;

  if (title && typeof title === 'function') {
    title = title.call(vm);
  }

  return Promise.resolve(title);
}

export default {
  created() {
    getTitle(this).then(title => {
      if (title) document.title = title;
    });
  },
};
