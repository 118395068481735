// "url": "https://api.ellipsis-drive.com/v2/path/9e5e8d98-cc4d-4131-ae3b-3817f6798b02/raster/timestamp/c2a22fec-5c79-42f3-804c-975971f132c8/tile/{z}/{x}/{y}?layer=859a0131-1727-4ba7-9a6d-d9a55f22023c",
// "type": "xyz",
// "alias": "2019 Tree Canopy",
// "visible": true,
// "layer_type": "image"

class RasterLayer {
  static LAYER_ID = 'canopy_raster';
  static SOURCE_ID = 'canopy_raster_source';

  constructor({tileUrl, name, visible = false}) {
    this._tileUrl = tileUrl;
    this._name = name;
    this._visible = visible;
  }

  // doing this instead of having to different RasterLayer classes in MapAdapter and checking instanceof
  isImageryType() {
    return true;
  }

  // only 1 RasterLayer at this time, so 1 hardcoded id is fine
  id() {
    return RasterLayer.LAYER_ID;
  }

  sourceId() {
    return RasterLayer.SOURCE_ID;
  }

  tileUrl() {
    return this._tileUrl;
  }

  name() {
    return this._name;
  }

  visible() {
    return this._visible;
  }
}

export default RasterLayer;
