import MapboxCanopyMap from './canopy-map';

const map = MapboxCanopyMap;

// have this be an aggregate and manage mapbox source internally
class RasterLayer {
  constructor(layer) {
    this._layer = layer;
  }

  show() {
    // layer and source id's are a responsibility of mapbox-related code (or should be)
    if (map.getLayer(this.id())) {
      map.showLayer(this.id());
    } else {
      this._add();
    }
  }

  hide() {
    map.hideLayer(this.id());
  }

  id() {
    return this._layer.id();
  }

  isRasterType() {
    return this._layer.isImageryType();
  }

  _add() {
    this._addSource();

    map.addRasterLayer(this.id(), this._sourceId());
  }

  _addSource() {
    map.addSource(this._sourceId(), {
      type: 'raster',
      tileUrl: this._layer.tileUrl(),
      tileSize: 128,
      // it has trouble rendering tiles around 19-20 zoom level. this is less performant
      //   than default of 15, but raster really has more detail at higher zoom levels
      maxzoom: 19,
    });
  }

  _sourceId() {
    return this._layer.sourceId();
  }
}

export default RasterLayer;
