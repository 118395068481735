// import * as _ from 'lodash';
import * as Map from '@/map';
import {Utils} from '@/shared';
import inferno from 'scale-color-perceptual/inferno';
const {CustomControl} = Map;

const PARKS_TILES_BASE_URL = `${Utils.getEnv('VUE_APP_BASE_URL')}/customers`;

const PARKS_SOURCE_ID = 'parks-tiles';
const MAP_STRING_NAME = 'parks-map';

let map, selectedLayer;

class Mapbox {
  // km 6/22/21: this is only rendered after config is loaded so just pass it (config)
  // through instead of customer like vue components
  render({
    mapName,
    mapSecondName,
    geocoderCountries,
    zoom,
    center,
    embedded,
    afterRender,
  }) {
    map = new Map.Mapbox({
      container: MAP_STRING_NAME,
      hash: embedded ? false : MAP_STRING_NAME,
      geocoderCountries,
      zoom,
      center,
      embedded,
    });

    return new Promise(resolve => {
      // km: things to do after style change when toggling satellite layer
      // km: gets called after map.setStyle in the show*****Layer() functions
      map.onLoadAndStyleChange(() => {
        this._addParksSource({mapName, mapSecondName});
        afterRender();
        resolve();
      });
    });
  }

  getMap() {
    // temp until new ParksMap class is setup
    return map;
  }

  addCustomControl(component, position) {
    map.addControl(new CustomControl(component), position);
  }

  setLayer(layer) {
    // temp use of _selectedLayerId, change in selectLayer types until default layer is setup first time using Layer entity
    // temp if for if using selectLayer at the beginning setup
    if (selectedLayer && map.map.getLayer(this._selectedLayerId())) {
      map.removeLayer(this._selectedLayerId());

      if (selectedLayer.polygonGeometry())
        map.removeLayer('feature-highlighted');
    }

    selectedLayer = layer;

    map.addHoverCursorChange(selectedLayer.id()); // don't add on mobile?
    this._addDefaultLayer(); // TODO: change function name
  }

  // temp until this file uses Layer entity
  _selectedLayerId() {
    if (typeof selectedLayer.id === 'function') return selectedLayer.id();

    return selectedLayer.name;
  }

  _addParksSource({mapName, mapSecondName}) {
    map.addSource(PARKS_SOURCE_ID, {
      type: 'vector',
      maxzoom: 15, // ap 12/8/21: Only request tiles up to z15, overzoom after that
      // km 7/2/21: maybe extract url building to config.js, it's very similar there
      tiles: [
        `${PARKS_TILES_BASE_URL}/${mapName}/${
          mapSecondName ? `${mapSecondName}/` : ''
        }parks/{z}/{x}/{y}.pbf`,
      ],
    });
  }

  _addDefaultLayer() {
    if (map.map.getLayer(this._selectedLayerId())) return;

    if (selectedLayer.pointGeometry()) {
      this._addPointLayer();
    } else if (selectedLayer.polygonGeometry()) {
      this._addPolygonLayers();
    } else {
      console.error(
        'Unsupported Parks Map layer geomType:',
        selectedLayer.geomType()
      );
    }
  }

  _addPointLayer() {
    const symbology = selectedLayer.symbologyField().name();

    // map.addLayer(map.map.U.properties({ // U.properties() returns a Mapbox Layer object, if using mapbox-gl-utils
    map.addLayer({
      id: this._selectedLayerId(),
      type: 'circle',
      source: PARKS_SOURCE_ID, // km: this has to correspond to the source above
      'source-layer': this._selectedLayerId(), // km: this is the actual layer name in the file (as it is set by CEM Data Pipeline)
      paint: {
        'circle-radius': [
          'interpolate',
          ['linear'],
          ['zoom'],
          5,
          1, // zoom is 5 (or less) -> circle radius will be 1px
          12,
          3,
          17,
          4,
          20,
          5, // zoom is 20 (or greater)) -> circle radius will be 5px
        ],
        'circle-color': ['match', ['get', symbology], ...this._colorScale()],
      },
    });
  }

  _addPolygonLayers() {
    const symbology = selectedLayer.symbologyField().name();

    map.addLayer(
      {
        id: this._selectedLayerId(),
        type: 'fill',
        source: PARKS_SOURCE_ID,
        'source-layer': this._selectedLayerId(),
        paint: {
          'fill-color': ['match', ['get', symbology], ...this._colorScale()],
          'fill-opacity': 0.7,
          'fill-outline-color': '#c6c7c3',
        },
      },
      /* tp 8/27/21: This is the highest label layer in our current Mapbox Style,
       * however our canopy layer is still getting inserted on top of every other
       * layer, which hides labels. Might be a bug in Mapbox version we're on. */
      'road-label-simple'
    );

    map.addLayer(
      {
        id: 'feature-highlighted',
        type: 'line',
        source: PARKS_SOURCE_ID,
        'source-layer': this._selectedLayerId(),
        paint: {
          'line-color': '#00FFFF',
          'line-width': 5,
          'line-opacity': 0.5,
        },
        layout: {
          'line-cap': 'round',
        },
        filter: ['in', 'id', ''],
      },
      'road-label-simple'
    );
  }

  // no Mapbox code in here, move up out of Mapbox infra layer
  // also, duplicated elsewhere
  _colorScale() {
    const symbologyCategories = selectedLayer.symbologyField().categoryValues();

    const colorScale = symbologyCategories
      .map((value, i) => [value, inferno(1 - i / symbologyCategories.length)])
      .flat();

    colorScale.push(inferno(0)); // km: add `other` color (black for inferno)

    return colorScale;
  }
}

const singleton = new Mapbox();
Object.freeze(singleton); // km 1/1/20: this is because we need all references
// to the map to only be this one instance and this class provides the interface

export default singleton;
