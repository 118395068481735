import {BaseMapStoreModule} from '@/map';
import {Config} from '@/shared';
import {Repository} from '@/canopy-map';

const FETCH_CONFIG = 'FETCH_CONFIG',
  SELECT_LAYER = 'SELECT_LAYER',
  SELECT_SYMBOLOGY_ATTRIBUTE = 'SELECT_SYMBOLOGY_ATTRIBUTE',
  TOGGLE_SOURCE = 'TOGGLE_SOURCE';

const CanopyMapStoreModule = BaseMapStoreModule.extend({
  state: {
    currentLayerId: null,
    symbologyAttribute: null,
    rasterToggledOn: false,
  },
  mutations: {
    [SELECT_LAYER](state, layerId) {
      state.currentLayerId = layerId;
    },
    [SELECT_SYMBOLOGY_ATTRIBUTE](state, symbologyAttribute) {
      state.symbologyAttribute = symbologyAttribute;
    },
    [TOGGLE_SOURCE](state) {
      state.rasterToggledOn = !state.rasterToggledOn;
    },
  },
  actions: {
    fetchConfig({commit}, mapSet) {
      // TODO pull all the after fetch stuff out of here
      return Config.loadCanopyMap(mapSet.mapName(), mapSet.mapSubName())
        .then(config => {
          commit(FETCH_CONFIG, config);
        })
        .then(config => {
          // error gets set on config, maybe better to pass back here?
          if (config && config.error) {
            return console.error(
              `Error fetching canopy map config: ${config.error}`
            );
          }

          const canopyMap = Repository.fetch(mapSet);

          Repository.saveCurrentLayer(mapSet.key(), canopyMap);
        });
    },
    selectLayer({commit}, layer) {
      commit(SELECT_LAYER, layer.id());
    },
    selectSymbologyAttribute({commit}, symbologyAttribute) {
      commit(SELECT_SYMBOLOGY_ATTRIBUTE, symbologyAttribute);
    },
    toggleSource({commit}) {
      commit(TOGGLE_SOURCE);
    },
  },
});

export default CanopyMapStoreModule;
