import ColorScaleService from '../../color-scale-service';
import HistogramChart from '../../../interface/details-overlay/histogram/histogram-chart';

const HISTOGRAM_CHART_ELEMENT_ID = 'histogram';

class HistogramService {
  constructor(layer, symbologyAttribute) {
    this._layer = layer;
    this._symbologyAttribute = symbologyAttribute;
  }

  setupChart() {
    if (!this._layer || !this._symbologyAttribute) return;

    // not accomodating different zoom levels/sets of data rendered on the map, just using everything
    HistogramChart.createChart(
      HISTOGRAM_CHART_ELEMENT_ID,
      this._layer,
      this._symbologyAttribute
    );

    this._average = HistogramChart.getAverage();
    this._averageColor = HistogramChart.getAverageColor();
  }

  addLineForSelectedPolygon(polygon) {
    HistogramChart.removeSelectionLine();
    this._selectionValue = null;

    if (!polygon) return;

    HistogramChart.addSelectionLine(polygon);
    this._selectionValue = HistogramChart.getSelectionValue();
  }

  gradientCss() {
    return `linear-gradient(to right,${this._colors()})`;
  }

  // have to call addLineForSelectedPolygon before this
  selectionValue() {
    return `${this._selectionValue}${this._appendTo()}`;
  }

  // have to call setupChart before this
  averageFormatted() {
    return `${this._average}${this._appendTo()}`;
  }
  // have to call setupChart before this
  averageColor() {
    return this._averageColor;
  }

  selectionLabel() {
    if (!this._symbologyAttribute) return;

    return `Average ${this._symbologyAttribute.alias()}`;
  }

  /**
   * Returns a string of colors for css linear gradient.
   *
   * e.g. '#000000, #111111' for positive-only scales
   *  or  '#000000, #111111 10%, #222222' including negative scale
   *
   * @return {string} color scale for positive-only and negative-positive scales
   */
  _colors() {
    if (!this._symbologyAttribute) return; // check still needed?

    const service = new ColorScaleService(
      this._layer,
      this._symbologyAttribute
    );

    const colorScale = service.getColorScale();

    if (colorScale.supportNegativeValues()) {
      const zeroPercent = service.gradientZeroColorPercent();

      const colors = colorScale.negativeToPositiveColors();

      return `${colors[0]}, ${colors[1]} ${zeroPercent}%, ${colors[2]}`;
    } else {
      return colorScale.positiveColors().join(', ');
    }
  }

  _appendTo() {
    return (
      (this._symbologyAttribute && this._symbologyAttribute.appendTo()) || ''
    );
  }
}

export default HistogramService;
