var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('HeaderNav'),_c('v-main',[_c('v-container',{class:{mobile: _vm.mobile},attrs:{"fluid":""}},[(!_vm.mobile && _vm.components.intro && _vm.components.intro.active)?_c('v-row',{staticClass:"text-center bg-urban-light d-none d-md-flex"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"my-16",attrs:{"cols":"8"}},[_c('h1',{staticClass:"secondary--text display-1 font-weight-bold ma-5",domProps:{"innerHTML":_vm._s(_vm.components.intro && _vm.components.intro.title)}}),_c('p',{staticClass:"subheading font-weight-regular ma-5",domProps:{"innerHTML":_vm._s(_vm.components.intro && _vm.components.intro.body)}})]),_c('v-col',{attrs:{"cols":"2"}})],1):_vm._e(),(
          _vm.components.treeMap &&
          _vm.components.treeMap.active &&
          (_vm.mobile ? _vm.activeTab == 'tree-map' : true)
        )?_c('TreeMap'):_vm._e(),(_vm.components.learnMore && _vm.components.learnMore.active)?_c('v-row',{staticClass:"text-center bg-urban-dark d-none d-md-flex",attrs:{"width":"100%"}},[_c('v-row',{staticClass:"white--text my-16",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"text-h4 font-weight-bold my-4",domProps:{"innerHTML":_vm._s(_vm.components.learnMore.title)}}),_c('p',{staticClass:"subheading ma-4",domProps:{"innerHTML":_vm._s(_vm.components.learnMore.body)}})]),_c('v-col',{attrs:{"cols":"3"}})],1)],1):_vm._e(),(
          _vm.components.canopyMap &&
          _vm.components.canopyMap.active &&
          (_vm.mobile ? _vm.activeTab == 'canopy-map' : true)
        )?_c('CanopyMap'):_vm._e(),(
          _vm.components.parksMap &&
          _vm.components.parksMap.active &&
          (_vm.mobile ? _vm.activeTab == 'parks-map' : true)
        )?_c('ParksMap'):_vm._e()],1)],1),(_vm.mobile)?_c('v-bottom-navigation',{attrs:{"app":"","dark":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.components.treeMap && _vm.components.treeMap.active)?_c('v-btn',{attrs:{"value":"tree-map"}},[_c('span',[_vm._v("Tree Map")]),_c('v-icon',[_vm._v("mdi-pine-tree")])],1):_vm._e(),(_vm.components.canopyMap && _vm.components.canopyMap.active)?_c('v-btn',{attrs:{"value":"canopy-map"}},[_c('span',[_vm._v("Canopy Map")]),_c('v-icon',[_vm._v("mdi-tree")])],1):_vm._e(),(_vm.components.parksMap && _vm.components.parksMap.active)?_c('v-btn',{attrs:{"value":"parks-map"}},[_c('span',[_vm._v("Parks Map")]),_c('v-icon',[_vm._v("mdi-home")])],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }