import ColorScaleService from '../../application/color-scale-service';
import MapboxCanopyMap from './canopy-map';

// TODO: have sourceId come from CEM domain (or is this too much coupling, already done for RasterLayer)
const VECTOR_SOURCE_ID = 'canopy_vector_tiles';
const map = MapboxCanopyMap;

// actually have this be an aggregate and manage mapbox source internally
// how to ensure this file would be wholey re-written if mapbox were switched out,
//   and that nothing is in here that isn't related only to mapbox
// this file can depend on knowledge of how mapbox works, but shouldn't depend on anything CEM-related

// VectorPolygonSelectableLayer?
class VectorLayer {
  constructor(layer) {
    this._layerId = layer.id();
    this._highlightLayerId = `${this._layerId}_highlighted`;
    this._layer = layer;
  }

  addSource(tileUrl) {
    map.addSource(VECTOR_SOURCE_ID, {type: 'vector', tileUrl});
  }

  show() {
    if (map.getLayer(this._layerId)) {
      map.showLayer(this._layerId);
      map.showLayer(this._highlightLayerId);
    } else {
      this.add();
    }
  }

  add() {
    const field = this._layer.symbologyField();

    // TODO: this should be moved up out of mapboxAdapter stuff. could calc on
    //   repo.fetch/selectSymbologyAttribute, then have come through as prop on layer
    const colorScaleService = new ColorScaleService(this._layer, field);

    const fillColor = this._fillColor(
      field.name(),
      colorScaleService.getColorSteps()
    );

    map.addLayer(this._layerId, VECTOR_SOURCE_ID, this._layerId, 'fill', {
      paint: {
        'fill-color': fillColor,
        'fill-opacity': 0.7,
        'fill-outline-color': '#c6c7c3',
      },
    });

    map.addVectorLineLayer(
      this._highlightLayerId,
      VECTOR_SOURCE_ID,
      this._layerId
    );

    map.cursorAsPointerOnHover(this._layerId);
  }

  hide() {
    map.hideLayer(this._layerId);
    map.hideLayer(this._highlightLayerId);
  }

  updateSymbology(name, steps) {
    // only the layer fill depends on symbology field/attribute (not highlight layer, so don't change that layer)
    const fillColorExpression = this._fillColor(name, steps);
    map.updateFillColorExpression(this._layerId, fillColorExpression);
  }

  id() {
    return this._layerId;
  }

  highlightId() {
    return this._highlightLayerId;
  }

  isRasterType() {
    return this._layer.isImageryType();
  }

  // private

  /**
   * Returns a mapbox step expression for a list of value/color pairs
   *
   * example:
   *   ['step', ['get', 'pid'], '#006837', 26, '#7e5f30', 32, '#a8925f', ...]
   *
   * @argument {string} propertyName - key to use to get value from property
   * @argument {string} colorSteps - array of value/color pairs for steps spanning all possible values
   * @return {array}
   */
  _fillColor(propertyName, colorSteps) {
    const fillSteps = colorSteps.flat();
    fillSteps.shift(); // remove first step input as it only needs output

    // use `step` for discreet colors/multi-hue.
    // Can't have floats as keys with `match`, but floats can be inputs for `step`. could use `pid` for keys for `match`, despite duplicate color values.
    return ['step', ['get', propertyName], ...fillSteps];
  }
}

export default VectorLayer;
