import Axios from 'axios';
import {Utils} from '@/shared';

let baseURL = Utils.getEnv('VUE_APP_BASE_URL');
// Normalize baseURL by removing any trailing slashes
baseURL = baseURL.replace(/\/+$/, '');

const client = Axios.create({
  baseURL: `${baseURL}/customers`,
  headers: {Accept: 'application/json'},
});

let cache = {};

const loadCached = url => {
  if (cache[url]) {
    return Promise.resolve(cache[url]);
  }

  // TODO add better error handling
  return (
    client
      .get(url)
      // status is still 200 when not found (because of AWS file missing settings)
      .then(({data}) => {
        if (typeof data !== 'object') {
          // it's probably a string of html saying nothing found
          return {error: `unable to load ${url}. response receieved:\n${data}`};
        }

        cache[url] = data;
        return data;
      })
  );
};

const loadConfig = (url, customer, site) => {
  return loadCached(url).then(data => {
    if (!data.error) {
      // TODO get rid of this and just use mapSet mapName()/mapSubName()
      data.customer = customer; // km 6/22/21: needed for TreeMapMapbox._addTreesSource()
      data.site = site; // km 6/22/21: needed for TreeMapMapbox._addTreesSource()
    }

    return data;
  });
};

const Config = {
  loadHosted(customer, site) {
    return loadConfig(
      `/${customer}/${site ? `${site}/` : ''}hosted_config.json`,
      customer,
      site
    );
  },
  loadTreeMap(customer, site) {
    return loadConfig(
      `/${customer}/${site ? `${site}/` : ''}tree_map_config.json`,
      customer,
      site
    );
  },
  loadCanopyMap(customer, site) {
    return loadConfig(
      `/${customer}/${site ? `${site}/` : ''}canopy_map_config.json`,
      customer,
      site
    );
  },
  loadParksMap(customer, site) {
    return loadConfig(
      `/${customer}/${site ? `${site}/` : ''}parks_map_config.json`,
      customer,
      site
    );
  },
  fetchLayerData(mapName, secondMapName, layerName) {
    return loadCached(
      `/${mapName}/${secondMapName ? `${secondMapName}/` : ''}${layerName}.json`
    ).then(({data}) => data);
  },
};

export default Config;
