import {Feature} from '@/map';
import * as _ from 'lodash';
import {WorkRecordRepository} from '@/parks-map';

const OMIT_PROPERTIES = ['photos'];

class FeaturePresenter {
  constructor(feature, mapSet) {
    this._feature = feature;
    this._mapSet = mapSet;
  }

  properties() {
    return this._formatProperties(this._feature.properties());
  }

  layerName() {
    // similar question to workRecords() since it's an associated entity
    return this._feature.layer().name();
  }

  workEnabled() {
    return this._feature.layer().enableWorkRecords();
  }

  noWork() {
    // workRecords are not passed to Feature entity in currentFeature getter, so
    // have to get from repository/store separately, otherwise could get from
    // this._feature once instantiation and factory-like work is removed from
    // store getter
    const workRecords = WorkRecordRepository.getAllFromCurrentFeature(
      this._mapSet
    );

    return !workRecords.length;
  }

  // this functionality is duplicated in the beginning of TreeDetails' translate()
  _formatProperties(properties) {
    properties = _.omit(properties, Feature.INTERNAL_FIELDS, OMIT_PROPERTIES);
    const fields = this._feature.layer().fields();

    return _.transform(
      properties,
      (result, value, name) => {
        const field = fields.find(field => field.name() === name);
        const valueLookup = field.values() && _.find(field.values(), {value});

        result[field.alias()] = (valueLookup && valueLookup.alias) || value;
      },
      {}
    );
  }
}

export default FeaturePresenter;
