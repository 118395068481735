import {BaseMap} from '@/map';
import ParksMapMapbox from './index';

class ParksMap extends BaseMap {
  constructor(map = ParksMapMapbox.getMap().map) {
    super(map);
  }
}

// const singleton = new ParksMap();
// Object.freeze(singleton); // km 1/1/20: this is because we need all references
// // to the map to only be this one instance and this class provides the interface

// export default singleton;

export default ParksMap; // no singleton while ParksMapMapbox is managing the Mapbox map instance
