class WorkRecordPresenter {
  constructor(workRecord, layer) {
    this._workRecord = workRecord;
    this._fields = layer.workRecordFields();
  }

  title() {
    return this._workRecord.work || 'Untitled Work Record';
  }

  subtitle() {
    let subtitle = '';

    if (this._status()) subtitle += `<strong>${this._status()}</strong>`;
    if (this._status() && this._date()) subtitle += ' - ';
    if (this._date()) subtitle += this._date();

    return subtitle;
  }

  comments() {
    return this._workRecord.comments;
  }

  photos() {
    // this seems better to return [], but elsewhere it is null and this could cause confusion, makes singlePhoto() simpler though
    if (!this._workRecord.photos) return [];

    return this._workRecord.photos.map(photo => photo.url);
  }

  singlePhoto() {
    return this.photos().length === 1;
  }

  photoIcon(showPhotos) {
    if (showPhotos) {
      return 'mdi-image-minus';
    } else {
      return this.singlePhoto() ? 'mdi-image' : 'mdi-image-multiple';
    }
  }
  _date() {
    const {dateCompleted, dueDate} = this._workRecord;

    if (dateCompleted && dueDate) {
      const completed = this._formatDate(dateCompleted);
      const due = this._formatDate(dueDate);

      return `${completed} (Due: ${due})`;
    } else {
      return this._formatDate(dateCompleted || dueDate);
    }
  }

  // this can't be an uncommon piece of code (translating based on lookups), look to extract
  _status() {
    // TODO: pull name of status field out into variable or something
    const statusLookups = this._fields.find(
      field => field.name === 'work_status'
    ).values;

    // return statusLookups[this._workRecord.status] || this._workRecord.status;

    const lookup = statusLookups.find(
      lookup => lookup.value === this._workRecord.status
    );

    return lookup.alias;

    // return lookup ? lookup.alias : this._workRecord.status;
  }

  _formatDate(date) {
    if (!date) return;

    // TODO get locale from config. attempt to pull but have this as default until it's included
    const locale = 'en-UK';

    return new Date(date).toLocaleDateString(locale, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
}

export default WorkRecordPresenter;
