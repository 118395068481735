import component from './interface/parks-map';
import WorkRecordEntity from './domain/work-record';
import DateFilter from './domain/work-record/date-filter';
import WorkRecordRepository from './infrastructure/work-record/vuex-repository';
import UseCases from './application/use-cases';
import ParksMap from './domain/parks-map';
import Adapter from './infrastructure/parks-map-adapter';
import Mapbox from './interface/mapbox'; // this to be deprecated over time

// new subclass for map interactions, eliminate references to mapbox from all but infra layer
import Map from './interface/mapbox/parks-map';

import Factory from './application/parks-map-factory'; // rename file/class name?
import StoreModule from './infrastructure/store-module';

// sub-modules/namespaces not great, maybe another index.js at the next level?
const WorkRecord = {
  Entity: WorkRecordEntity,
  DateFilter,
};

export {
  component as default,
  WorkRecordRepository, // can't figure out how to do sub-modules for this...
  WorkRecord,
  UseCases,
  ParksMap,
  Adapter,
  Mapbox,
  Map,
  Factory,
  StoreModule,
};
