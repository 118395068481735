// {
// "pid": 866,
// "name": "site_name",
// "alias": "Site Name",
// "legendItem": false,
// "topFive": [],
// "values": []
// }

class Field {
  constructor({
    name,
    alias,
    values,
    legendItem,
    colorBreaks,
    appendTo,
    min,
    max,
  }) {
    this._name = name;
    this._alias = alias;
    this._values = values;

    this._legendItem = legendItem; // this is only used by parks map

    // thses are only used by canopy map
    this._colorBreaks = colorBreaks;
    this._appendTo = appendTo;
    this._min = min;
    this._max = max;
  }

  toObject() {
    return {
      name: this._name,
      alias: this._alias,
      values: this._values,
      legendItem: this._legendItem,
      colorBreaks: this._colorBreaks,
      appendTo: this._appendTo,
      min: this._min,
      max: this._max,
    };
  }

  name() {
    return this._name;
  }

  alias() {
    return this._alias;
  }

  categoryValues() {
    return this._values.map(category => category.value);
  }

  values() {
    return this._values;
  }

  legendItem() {
    return this._legendItem;
  }

  colorBreaks() {
    return this._colorBreaks;
  }

  appendTo() {
    return this._appendTo;
  }

  min() {
    return this._min;
  }

  max() {
    return this._max;
  }
}

export default Field;
