import {BaseMapStoreModule, Feature} from '@/map'; // maybe rename BaseMapStoreModule now?
import {Config} from '@/shared';
// km: too many circular dependency issues if these are imported from '@/parks-map'
import ParksMap from '../domain/parks-map';
import Adapter from './parks-map-adapter';
import Factory from '../application/parks-map-factory';

const FETCH_CONFIG = 'FETCH_CONFIG',
  SELECT_LAYER = 'SELECT_LAYER',
  SELECT_FEATURE = 'SELECT_FEATURE',
  TOGGLE_WORK = 'TOGGLE_WORK',
  FILTER_WORK_RECORDS = 'FILTER_WORK_RECORDS';

const ParksMapStoreModule = BaseMapStoreModule.extend({
  state: {
    layers: [],
    currentLayerId: null,
    currentFeature: null, // just data, not an entity
    showWork: false,
    currentFeatureWorkRecords: [],
    workRecordsDateFilter: null,
  },
  mutations: {
    [SELECT_LAYER](state, layerId) {
      state.currentLayerId = layerId;
    },
    [SELECT_FEATURE](state, feature) {
      state.currentFeature = feature; // if entity, reduce to object of data
      state.currentFeatureWorkRecords = (feature && feature.workRecords) || [];
    },
    [TOGGLE_WORK](state) {
      state.showWork = !state.showWork;
    },
    [FILTER_WORK_RECORDS](state, dateFilter) {
      state.workRecordsDateFilter = dateFilter;
    },
  },
  actions: {
    fetchConfig({commit, getters}, mapSet) {
      return Config.loadParksMap(mapSet.mapName(), mapSet.mapSubName()).then(
        config => {
          commit(FETCH_CONFIG, config);

          // this doesn't make sense to have here, not an event...
          // fetch config also doesn't make sense. view or load event might make sense
          // this has to be set so that the currentLayer getter works later
          commit(SELECT_LAYER, getters.map.currentLayer().id());
        }
      );
    },
    // maybe change function name to be selectLayerById(), or pass in full layer
    selectLayer({commit, getters, state, dispatch}, layerId) {
      if (state.currentFeature) dispatch('selectFeature', null);

      commit(SELECT_LAYER, layerId);

      // does this belong elsewhere?
      getters.mapAdapter.selectLayer(getters.currentLayer);
    },
    selectFeature({commit, getters}, feature) {
      const featureData = feature ? feature.toObject() : null;
      commit(SELECT_FEATURE, featureData);

      getters.mapAdapter.selectFeature(feature, getters.currentLayer);
    },
    toggleWork({commit}) {
      commit(TOGGLE_WORK);
    },
    workRecordsDateFilter({commit}, dateFilter) {
      commit(FILTER_WORK_RECORDS, dateFilter);
    },
  },
  getters: {
    layers: ({config}) => {
      if (!config.layers) return;

      // maybe have property name translations from buildLayer take place in fetchConfig, which would store data in state with correct property names
      return config.layers.map(Factory.buildLayer);
    },
    map: (state, getters) => {
      return new ParksMap({
        layers: getters.layers,
        defaultVisibleLayer: state.config.map.defaultVisibleLayer,
      });
    },
    currentLayer: ({currentLayerId}, getters) => {
      if (!getters.layers) return;

      return getters.layers.find(layer => layer.id() === currentLayerId);
    },
    currentFeature: ({currentFeature, currentLayerId}, getters) => {
      // currentLayerId is null before config loaded (and computed tries currentFeature on page load)
      if (!currentFeature || !currentLayerId) return; // pointing to need for Null Object Pattern

      return new Feature({
        ...currentFeature,
        layer: getters.currentLayer,
      });
    },
    mapAdapter: (_state, _getters, rootState) => {
      return new Adapter(rootState.mapName, rootState.mapSecondName);
    },
    workRecordsDateFilter: state => state.workRecordsDateFilter,
  },
});

export default ParksMapStoreModule;
