import {Utils} from '@/shared';
import Mapbox from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import U from 'mapbox-gl-utils';

Mapbox.accessToken = Utils.getEnv('VUE_APP_MAPBOX_ACCESS_TOKEN');

class MapboxMap {
  constructor({container, hash, geocoderCountries, zoom, center, embedded}) {
    this.map = new Mapbox.Map({
      container,
      hash,
      style: Utils.getEnv('VUE_APP_MAPBOX_STYLE'), //'mapbox://styles/mapbox/streets-v11',//'mapbox://styles/mapbox/satellite-streets-v11'
      zoom,
      center,
      minzoom: 0,
      maxzoom: 20,
      attributionControl: false, // attribution added with customizations below
    });

    // U.init(this.map, Mapbox);

    this._setupScrollAndDragWorkarounds(embedded);

    if (geocoderCountries) {
      this.addControl(this._geocoder(center, geocoderCountries)); // km: this shouldn't be added here as this is specific to tree map
    }

    this.addControl(new Mapbox.NavigationControl({showCompass: false}));
    this.addControl(new Mapbox.GeolocateControl());
    this.addControl(
      new Mapbox.AttributionControl({compact: true}),
      'bottom-right'
    );
  }

  addSource(sourceId, parameters) {
    this.map.addSource(sourceId, parameters);
  }

  addLayer(parameters) {
    this.map.addLayer(parameters);
  }

  removeLayer(layerId) {
    this.map.removeLayer(layerId);
  }

  addMarker(lngLat, {element, color, anchor}) {
    return new Mapbox.Marker({element, color, anchor})
      .setLngLat(lngLat)
      .addTo(this.map);
  }

  addControl(control, position = 'top-left') {
    this.map.addControl(control, position);
  }

  onClick(layerId, handler) {
    if (!handler) {
      handler = undefined;
    }

    this.map.on('click', layerId, handler);
  }

  onLoadAndStyleChange(handler) {
    this.map.on('style.load', () => handler()); // km: style.load isn't part of the public api, but styledata doesn't work either as referenced in https://stackoverflow.com/a/60514976/2009660
  }

  addHoverCursorChange(layerId) {
    this.map.on('mouseenter', layerId, () => this.setCursor('pointer'));
    this.map.on('mouseleave', layerId, () => this.setCursor('')); // km: reset cursor when not hovering
  }

  setCursor(style) {
    this.map.getCanvas().style.cursor = style;
  }

  // km: this shouldn't be in this file as this is just for tree map, check above to see if it should be added
  _geocoder(center, geocoderCountries) {
    return new MapboxGeocoder({
      accessToken: Mapbox.accessToken,
      mapboxgl: Mapbox,
      marker: false, // km: might want this true
      collapsed: true,
      countries: geocoderCountries,
      proximity: center, // km: prioritize searches closer to this, possible?
      zoom: 15,
      flyTo: {
        zoom: 15,
      },
    });
  }

  // km 9/17/21: this is a temporary workaround for desktop (hosted) CEM maps
  //   to deal with scrolling down the page and not being able to scroll past
  //   the map because scrollZoom is enabled by default. The mobile embedded
  //   CEM maps are embedded in customer pages we can't control and must allow
  //   scrolling past the maps, so dragPan (one finger touch) is being disabled
  //   here for those, and two finger touch is being set up to allow zooming.
  _setupScrollAndDragWorkarounds(embedded) {
    this.map.scrollZoom.disable(); // km: temporary solution to scrolling past map on desktop, until we move the content sections and implement tabs or something infoatplanitgeo.atlassian.net/browse/TP2-50

    if (embedded && Utils.isMobile()) {
      this.map.dragPan.disable(); // km: solution to scrolling/dragging map causing map pan
      this.map.touchPitch.disable(); // km: disable two finger touch which pitches zoom by default

      this.map.on('touchstart', ({originalEvent}) => {
        // km: from https://github.com/mapbox/mapbox-gl-js/issues/2618#issuecomment-607260196
        if (this._isTwoFingerTouchEvent(originalEvent)) {
          this.map.dragPan.enable();
        } else {
          this.map.dragPan.disable();
        }
      });
    }
  }

  _isTwoFingerTouchEvent(event) {
    return event && 'touches' in event && event.touches.length > 1;
  }
}

export default MapboxMap;
