import {StoreModule as TreeMapStoreModule} from '@/tree-map';
import {StoreModule as CanopyMapStoreModule} from '@/canopy-map';
import {StoreModule as ParksMapStoreModule} from '@/parks-map';

const MapSetStoreModuleFactory = {
  build(mapSet, components) {
    const storeModule = this._initialModule(mapSet);

    this._addMapComponentStoreModules(storeModule, components);

    return storeModule;
  },

  _initialModule(mapSet) {
    return {
      namespaced: true,
      state: {
        key: mapSet.key(),
        mapName: mapSet.mapName(),
        mapSubName: mapSet.mapSubName(),
      },
      modules: {},
    };
  },

  // TODO: build up components object as before i.e. `{treeMap: true}`, then `if (treeMap)`
  _addMapComponentStoreModules(storeModule, components) {
    if (components.indexOf('treeMap') !== -1) {
      storeModule.modules.treeMap = TreeMapStoreModule;
    }

    if (components.indexOf('canopyMap') !== -1) {
      storeModule.modules.canopyMap = CanopyMapStoreModule;
    }

    if (components.indexOf('parksMap') !== -1) {
      storeModule.modules.parksMap = ParksMapStoreModule;
    }
  },
};

export default MapSetStoreModuleFactory;
