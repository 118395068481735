import chroma from 'chroma-js';

const LIMITS_GROUPS = 9;
const LIMITS_QUANTILE_MODE = 'q';

const ChromaQuantileDiscreetColorScale = {
  /*
   * returns Function that returns hex color for Number from chroma scale
   *
   * Chroma scale: A color scale, created with chroma.scale, is a function that maps numeric
   * values to a color palette. The default scale has the domain 0..1 and goes
   * from white to black.
   */
  getColorStepScale({colors, breaks}) {
    return value => {
      // use .domain(breaks) for continuous scale of colors vs classes for class breaks/discreet colors
      // .gamma() can't guarantee that it will not have brown for 0 or >0 values, even gamma(0.01) for GJ
      const scale = chroma.scale(colors).classes(breaks);
      return scale(value).hex();
    };
  },

  /*
   * returns:
   *   Array[10 (or LIMITS_GROUPS + 1)]
   *
   * example:
   *   [
   *     -30.0777873812, 0, 0.445872976291, 1.72982771714, 2.9211491077366665,
   *     4.271886770376667, 5.58794052075, 7.111715035566666, 9.39567931921,
   *     75.393081761,
   *   ];
   *
   * description:
   *   the canopy layer data for selected attribute is divided by quantile ranges
   */
  quantileBreaks(data) {
    return chroma.limits(data, LIMITS_QUANTILE_MODE, LIMITS_GROUPS);
  },
};

export default ChromaQuantileDiscreetColorScale;
