import * as _ from 'lodash';

// parks map feature (from config, but develop internal model that can be translated from tiles data)
// translation happens in FeatureRepository

// to consider: this entity might be useable for tree when clicked in TreeMap if coupling to ParksMap use/config can be avoided
class Feature {
  static INTERNAL_FIELDS = ['pid', 'work_records', 'item_id']; // belong in presenter?

  constructor({pid, coordinates, properties, layer, workRecords}) {
    this._pid = pid;
    this._coordinates = coordinates;

    this._properties = _.omit(properties, this.INTERNAL_FIELDS);

    this._layer = layer;
    this._workRecords = workRecords;
  }

  toObject() {
    return {
      pid: this._pid,
      coordinates: this._coordinates,
      properties: this._properties,

      // layer: this._layer.toObject(),

      workRecords: this._workRecordsObjects(),
    };
  }

  pid() {
    return this._pid;
  }

  layer() {
    return this._layer;
  }

  coordinates() {
    return this._coordinates;
  }

  properties() {
    return this._properties;
  }

  workRecords() {
    return this._workRecords;
  }

  _workRecordsObjects() {
    if (!this._workRecords) return;

    return this._workRecords.map(workRecord => workRecord.toObject());
  }

  // this is available via layer
  // geomType() {
  //   return this._geomType; // this._layer.geomType()
  // }
}

export default Feature;
