class CustomControl {
  constructor(button) {
    this._button = button;
  }

  onAdd() {
    return this._button.$el;
  }

  onRemove() {
    this._button.$destroy();
    this._button.$el.parentNode.removeChild(this._button.$el);
    this._map = undefined;
  }
}

export default CustomControl;
