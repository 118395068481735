import {Store, Utils} from '@/shared';
import CanopyMapFactory from '../application/canopy-map-factory';
import {Factory} from '@/parks-map'; // FIXME: needs to be moved/changed as this is used by canopy map now too
import * as _ from 'lodash';

const CANOPY_TILES_BASE_URL = `${Utils.getEnv('VUE_APP_BASE_URL')}/customers`;

const CanopyMapVuexRepository = {
  fetch(mapSet) {
    const {canopyMap} = Store.state[mapSet.key()];

    if (!this._configLoaded(canopyMap)) return;

    const {defaultZoom, defaultCenter, geocoderCountries} =
      canopyMap.config.map;
    const {currentLayerId, symbologyAttribute, rasterToggledOn} = canopyMap;

    return CanopyMapFactory.build({
      attributes: {
        defaultZoom,
        defaultCenter,
        geocoderCountries,
        currentLayerId,
        rasterToggledOn,
        vectorLayerTileUrl: this._getVectorLayerUrl(mapSet),
      },
      symbologyAttributeAttributes: symbologyAttribute,
      vectorLayerAttributes: this._getVectorLayerAttributes(canopyMap.config),
      rasterLayerAttributes: this._getRasterLayerAttributes(canopyMap.config),
    });
  },

  getCurrentLayer(mapSet) {
    // old code (alternative to building up entire canopyMap domain entity):
    // const currentLayerId = Store.state.canopyMap.currentLayerId;
    // const layers = this.getLayers(); // could be more efficient to match id before instantiating Layer for each in config
    // if (!layers) return;
    // return layers.find(layer => layer.id() === currentLayerId);

    const canopyMap = this.fetch(mapSet);
    if (!canopyMap) return;

    return canopyMap.currentLayer();
  },

  getRasterLayer(mapSet) {
    const canopyMap = this.fetch(mapSet);

    return canopyMap.rasterLayer();
  },

  saveCurrentLayer(mapSetKey, canopyMap) {
    const layer = canopyMap.currentLayer();

    // select symbologyAttribute before layer so watchers triggered on layer change have symbologyAttribute
    // this shouldn't be an event, or it should be a different one, and have user interactions use a differnet one
    Store.dispatch(
      `${mapSetKey}/canopyMap/selectSymbologyAttribute`,
      layer.symbologyField().toObject()
    );

    Store.dispatch(`${mapSetKey}/canopyMap/selectLayer`, layer);
  },

  getLayers(mapSet) {
    const {config} = Store.state[mapSet.key()].canopyMap;

    if (!config) return;

    // there is also customImagery alongside layers on config
    const layersAttributes = config.layers;

    if (!layersAttributes) return;

    return layersAttributes.map(Factory.buildLayer);
  },

  // private

  _configLoaded(canopyMap) {
    return !!(canopyMap && canopyMap.config && canopyMap.config.map);
  },

  _getVectorLayerAttributes(config) {
    return config.layers.map(layer => {
      return Factory._layerAttributes(layer); // TODO handle this layer factory
    });
  },

  _getRasterLayerAttributes(config) {
    // TODO: clean up after getting base branches sorted with new config
    const layerConfig = this._getRasterLayerConfig(config);

    return {
      // tileUrl is per layer, not per source (but only 1 layer/source right now)
      tileUrl: layerConfig.url,
      name: layerConfig.alias,
      visible: layerConfig.visible,
      // type 'xyz'
      // layer_type 'image'
    };
  },

  _getVectorLayerUrl(mapSet) {
    const mapName = mapSet.mapName();
    const mapSubName = mapSet.mapSubName();

    const secondSegment = mapSubName ? `${mapSubName}/` : '';
    return `${CANOPY_TILES_BASE_URL}/${mapName}/${secondSegment}canopy/{z}/{x}/{y}.pbf`;
  },

  _getRasterLayerConfig(config) {
    const rasterLayers = config.customImagery;

    if (_.isEmpty(rasterLayers)) return {visible: false}; // null object pattern using different attributes, not a different object

    return rasterLayers[rasterLayers.length - 1]; // get last (newest), should be only 1
  },
};

export default CanopyMapVuexRepository;
