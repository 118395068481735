import * as _ from 'lodash';
import AmCharts from './am-charts';
import ColorScaleService from '../../../application/color-scale-service';
import ColorScale from '../../../domain/color-scale';

const PERCENT_INCREMENT = 5;

let selectionValue, field, layer, data;

// TODO: fix chart to work with numbers other than percents
// TODO: dispose of chart when creating new one
const createChart = (elementId, currentLayer, symbologyAttribute) => {
  layer = currentLayer;
  field = symbologyAttribute;

  const xAxis = field.name(); // 'utc_pct'
  const yTitle = 'Number of Features';
  const yAxis = currentLayer.id(); // 'gj_cbg_10'

  const colorScale = new ColorScale(layer, field);
  data = colorScale.attributeData();

  const chartData = _buildChartData(xAxis, yAxis);
  AmCharts.createXYChart(elementId, chartData);

  AmCharts.addXAxis(xAxis, field.appendTo() || '');
  AmCharts.addYAxis(yTitle);
  AmCharts.addSeries(xAxis, yAxis);

  AmCharts.addSingleLine(_getLineFormatting(_calculateAverage()));
};

const addSelectionLine = polygon => {
  selectionValue = polygon.properties[field.name()];

  AmCharts.addSingleLine({value: _scaleToIncrement(selectionValue)});
};

const getAverage = () => {
  return Math.round(_calculateAverage());
};

const getAverageColor = () => {
  return _getLineFormatting(_calculateAverage()).color;
};

const getSelectionValue = () => {
  return Math.round(selectionValue);
};

const removeSelectionLine = () => {
  if (_.isNil(selectionValue)) return;
  AmCharts.removeSingleLine(_scaleToIncrement(selectionValue));
  selectionValue = null;
};

const _buildChartData = (xAxis, yAxis) => {
  const defaultDataPoint = {};
  defaultDataPoint[yAxis] = 0;

  const maxX = _scaleToIncrement(field.max()); // mapping here on data same as in mapbox code

  // 100% (maxXValue) / 5 number of data points. + 2 to include xValue for max and another after
  const chartData = Array.from(
    Array(maxX / PERCENT_INCREMENT + 2),
    (_value, index) => {
      const dataPoint = {};
      dataPoint[xAxis] = index * PERCENT_INCREMENT; // scale up to 5% increments
      return _.assign(dataPoint, defaultDataPoint);
    }
  ); // [{gj_cbg_10: 0, utc_pct: 0}, {gj_cbg_10: 0, utc_pct: 1}, ...]

  data.forEach(value => {
    const xIndex = Math.round(value / PERCENT_INCREMENT); // group to nearest 5% increment index. geography[fieldName] geography['utc_pct']

    // temp while not supporting negative as well
    if (xIndex < 0)
      return console.warn(
        `Skipping negative value (${xAxis}) for geography: ${value}`
      );

    chartData[xIndex][yAxis]++;
  });

  return chartData;
};

const _calculateAverage = () => {
  return _.sum(data) / data.length; // mapping here on data same as in mapbox code
};

const _getLineFormatting = preciseValue => {
  const colorScaleService = new ColorScaleService(layer, field);

  return {
    value: _scaleToIncrement(preciseValue),
    color: colorScaleService.calculateColor(preciseValue),
  };
};

const _scaleToIncrement = value => {
  return Math.round(value / PERCENT_INCREMENT) * PERCENT_INCREMENT;
};

export default {
  createChart,
  addSelectionLine,
  getAverage,
  getAverageColor,
  getSelectionValue,
  removeSelectionLine,
};
