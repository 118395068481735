import {Config} from '@/shared';
import {StoreModule as TreeMapStoreModule} from '@/tree-map';
import {StoreModule as CanopyMapStoreModule} from '@/canopy-map';
import {StoreModule as ParksMapStoreModule} from '@/parks-map';
import createLogger from 'vuex/dist/logger';
import Vue from 'vue';
import Vuex from 'vuex';
import {endsWith} from 'lodash';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

const FETCH_HOSTED_CONFIG = 'FETCH_HOSTED_CONFIG';

// TODO: extract all of this into MapSetStoreModuleFactory
// needs to be reconciled with cem-embed.vue code doing this stuff too on initial load
const getMapSetModule = (mapSet, {treeMap, canopyMap, parksMap}) => {
  const mapSetModule = {
    namespaced: true,
    state: {
      mapName: mapSet.mapName(),
      mapSubName: mapSet.mapSubName(),
      key: mapSet.key(),
    },
    modules: {},
  };

  if (treeMap && treeMap.active)
    mapSetModule.modules.treeMap = TreeMapStoreModule;

  if (canopyMap && canopyMap.active)
    mapSetModule.modules.canopyMap = CanopyMapStoreModule;

  if (parksMap && parksMap.active)
    mapSetModule.modules.parksMap = ParksMapStoreModule;

  return mapSetModule;
};

const getActiveComponents = ({components}) => {
  return Object.entries(components)
    .filter(([name, component]) => endsWith(name, 'Map') && component.active)
    .map(([name]) => name);
};

const Store = new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  // note: only store primitive data types (objects, arrays, strings, etc), not
  // entity/class instances, instead instantiate those in getters using state data
  state: {
    // root state/store
    embedded: true,

    // maybe setup hosted module too?
    // hosted specific, not used in embedded maps
    hostedConfig: {},
  },
  mutations: {
    [FETCH_HOSTED_CONFIG](state, config) {
      state.embedded = false; // maybe rename setupHosted() instead?
      state.hostedConfig = config;
    },
  },
  actions: {
    fetchHostedConfig({commit}, mapSet) {
      // TODO pull some of this stuff out of here
      return Config.loadHosted(mapSet.mapName(), mapSet.mapSubName())
        .then(config => {
          if (config.error) return false;

          const mapSetModule = getMapSetModule(mapSet, config.components);
          this.registerModule(mapSet.key(), mapSetModule);

          commit(FETCH_HOSTED_CONFIG, config);

          getActiveComponents(config).forEach(mapModule => {
            Store.dispatch(`${mapSet.key()}/${mapModule}/fetchConfig`, mapSet);
          });

          return true;
        })
        .catch(error => {
          console.error('Error fetching hosted config:', error);
          return false; // may want to check that it's the right kind of error
        });
    },
  },
  getters: {
    hostedConfigComponents: state => {
      return state.hostedConfig.components;
    },
  },
});

export default Store;
