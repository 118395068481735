<template>
  <v-select
    :items="layersSelectOptions"
    v-model="selectedLayerValue"
    @change="layerSelectChange"
    dense
    flat
    solo
    attach
    class="text-subtitle-1 font-weight-bold bg-transparent mx-2 mt-1 mb-n8"></v-select>
</template>

<script>
import MapSetService from '@/shared/infrastructure/map-set/service';

const getLayerSelectOptions = (store, mapSetKey) => {
  const layers = store.getters[`${mapSetKey}/parksMap/layers`];

  // presentation/ui formatting in here, or facade/presenter file, or in store?
  // ...not in store as that violates SRP, here for now
  return layers.map(layer => ({text: layer.name(), value: layer.id()}));
};

// maybe combine/extract with canopy-layer-select.vue
export default {
  inject: ['storeModule', 'mapSetKey'],
  data: () => ({
    selectedLayerValue: null,
    layersSelectOptions: [],
  }),
  created() {
    // this is more reliable than a vue component property watcher for updating view on async changes
    MapSetService.storeWatchOnce(
      [this.mapSetKey, 'parksMap', 'currentLayerId'],
      currentLayerId => {
        if (currentLayerId && !this.selectedLayerValue) {
          this.selectedLayerValue = currentLayerId;
        }

        this.layersSelectOptions = getLayerSelectOptions(
          this.$store,
          this.mapSetKey
        );
      }
    );
  },
  methods: {
    layerSelectChange(layerId) {
      this.$store.dispatch(`${this.mapSetKey}/parksMap/selectLayer`, layerId);
    },
  },
};
</script>

<style lang="scss">
.bg-transparent {
  .v-input__slot {
    background: transparent !important;
  }
}
</style>
