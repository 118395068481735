import {Utils} from '@/shared';
import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueRouter from 'vue-router';
import NotFound from '../../interface/not-found';
import Hosted from '../../interface/hosted';

Vue.use(VueRouter);

const gtmId = Utils.getEnv('VUE_APP_GOOGLE_TAG_MANAGER_ID');
if (gtmId) Vue.use(VueGtm, {id: gtmId});

const Router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      beforeEnter: (to, from, next) => {
        next({name: 'NotFound', params: {home: true}});
      },
    },
    {
      path: '/:mapName/:mapSubName?',
      name: 'Hosted',
      component: Hosted,
      props: true,
    },
    {
      path: '/:catchAll(.*)*',
      name: 'NotFound',
      component: NotFound,
      props: true,
    },
  ],
});

export default Router;
