import MapSetStoreModuleFactory from './store-module-factory';
import {Store} from '@/shared';
import {camelCase} from 'lodash';

const getComponentKeys = components => {
  return components.map(component => camelCase(component));
};

const MapSetService = {
  registerStoreModule(mapSet, components) {
    const componentsKeys = getComponentKeys(components);

    const mapSetStoreModule = MapSetStoreModuleFactory.build(
      mapSet,
      componentsKeys
    );

    Store.registerModule(mapSet.key(), mapSetStoreModule);
  },

  fetchMapComponentConfigurations(mapSet, components) {
    getComponentKeys(components).forEach(mapModule => {
      Store.dispatch(`${mapSet.key()}/${mapModule}/fetchConfig`, mapSet);
    });
  },

  storeWatchOnce(stateKeys, onChange) {
    // Initialize state keys with appropriate default values if they are not present
    stateKeys.forEach(key => {
      if (Store.state[key] === undefined) {
        Store.state[key] = key === 'currentLayerId' ? 'defaultLayerId' : {}; // or any appropriate default value
      }
    });

    const unwatch = Store.watch(
      state => {
        return stateKeys.reduce((state, key) => state[key], state);
      },
      newValue => {
        onChange(newValue);

        unwatch();
      }
    );

    // Manually call the onChange callback with the initial state
    const initialWatchedState = stateKeys.reduce(
      (acc, key) => acc[key],
      Store.state
    );
    onChange(initialWatchedState);
  },
};

export default MapSetService;
