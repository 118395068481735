class ColorScale {
  constructor(layer, field) {
    this._layer = layer;
    this._field = field;
  }

  attributeData() {
    return this._layer.data().map(feature => feature[this._field.name()]);
  }

  setQuantileBreaks(quantileBreaks) {
    this._quantileBreaks = quantileBreaks;
  }

  stepBreaks() {
    const breaks = [...this._quantileBreaks]; // copy to modify later

    if (!this.supportNegativeValues()) {
      return breaks;
    } else {
      breaks.splice(this._zeroIndex(), 1, 0); // replace closestBreakToZero with 0
      return breaks;
    }
  }

  scaleSetupData() {
    if (!this.supportNegativeValues()) {
      return [this._positiveOnlyScale()];
    } else {
      return [this._negativeScale(), this._positiveScale()];
    }
  }

  // could be a function on Field
  // same as this code in TP2: https://bitbucket.org/planitgeodev/treeplotter2/pull-requests/169#Lmain/js/classes/Canopy.jsT3279
  supportNegativeValues() {
    return this._colors().length === 3 && this._field.min() < 0;
  }

  gradientZeroColorPercent() {
    const numberOfColors = this._quantileBreaks.length - 1;
    return (this._zeroIndex() / numberOfColors) * 100;
  }

  negativeToPositiveColors() {
    return this._colors();
  }

  positiveColors() {
    return this._colors().slice(-2); // get last 2 of array
  }

  // private

  // TODO: have an value object encapsulate colors/breaks (for 3 below funcs)
  //   instead of just a raw JS object
  _positiveOnlyScale() {
    return {colors: this.positiveColors(), breaks: this.stepBreaks()};
  }

  _negativeScale() {
    return {
      colors: this.negativeToPositiveColors().slice(0, 2),
      breaks: this.stepBreaks().slice(0, this._zeroIndex() + 1),
    };
  }

  _positiveScale() {
    const breaks = this.stepBreaks();

    return {
      colors: this.positiveColors(),
      breaks: breaks.slice(this._zeroIndex(), breaks.length),
    };
  }

  _colors() {
    return this._field.colorBreaks();
  }

  _closestBreakToZero() {
    // this check is to handle situations where this._quantileBreaks first break is
    //   < 0 and the second break is > 0 and further from 0 than the first which
    //   results in the only negative break
    //
    //   i.e. _quantileBreaks of [-1.5, 2, 4, 5, ...] would get _closestBreakToZero()
    //   of -1.5 and then product stepBreaks of [0, 2, 4, 5, ...] resulting in
    //   not coloring negative values correctly (desired stepBreaks after this
    //   is [-1.5, 0, 4, 5, ...])
    if (this._onlyOneNegativeBreak()) return this._quantileBreaks[1];

    // copy this._quantileBreaks as .sort() modifies original
    const ascendingAbsoluteValueBreaks = [...this._quantileBreaks].sort(
      (a, b) => Math.abs(a) - Math.abs(b)
    );

    return ascendingAbsoluteValueBreaks[0];
  }

  _zeroIndex() {
    return this._quantileBreaks.indexOf(this._closestBreakToZero());
  }

  _onlyOneNegativeBreak() {
    const secondBreak = this._quantileBreaks[1];

    return (
      this.supportNegativeValues() &&
      secondBreak > 0 &&
      Math.abs(this._quantileBreaks[0]) < Math.abs(secondBreak)
    );
  }
}

export default ColorScale;
