var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend-symbology",class:{
    mobile: _vm.mobile,
    'mapboxgl-ctrl-group': !_vm.mobile,
    'mapboxgl-ctrl': !_vm.mobile,
    'buffer-overlay': _vm.bufferOverlay,
  }},[_c('v-select',{staticClass:"symbology font-weight-bold",attrs:{"items":_vm.symbologiesSelectOptions,"dense":"","flat":"","solo":"","attach":"","background-color":"none"},on:{"change":_vm.symbologySelectChange},model:{value:(_vm.symbologySelectedValue),callback:function ($$v) {_vm.symbologySelectedValue=$$v},expression:"symbologySelectedValue"}}),_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded && _vm.symbologySelected.topFive.length),expression:"loaded && symbologySelected.topFive.length"}],staticClass:"mb-n1 mt-n2 ml-3 topFive",attrs:{"label":"Show top 5","dense":""},on:{"change":_vm.topFiveSpeciesChange},model:{value:(_vm.topFive),callback:function ($$v) {_vm.topFive=$$v},expression:"topFive"}}),_c('div',{staticClass:"px-3 pb-2 symbology-category",class:{mobile: _vm.mobile}},[_vm._l((_vm.categories),function(category,index){return _c('div',{key:category.value},[_c('span',{staticClass:"color",style:({
          'background-color': _vm.colors(1 - index / _vm.categories.length),
        })}),_c('span',[_vm._v(_vm._s(category.alias))])])}),_c('div',[_c('span',{staticClass:"color",style:({'background-color': _vm.colors(0)})}),_c('span',[_vm._v("Other")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }