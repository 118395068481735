<template>
  <v-select
    v-model="symbologyAttribute"
    :items="symbologyAttributes"
    :item-text="item => item.alias()"
    @change="symbologyAttributeChange"
    :return-object="true"
    flat
    solo
    class="text-subtitle-2 font-weight-bold mx-2 mb-n10 bg-transparent">
  </v-select>
</template>

<script>
import {Interactions} from '@/canopy-map';

export default {
  inject: ['mapSet'],
  data() {
    return {
      symbologyAttribute: null,
    };
  },
  computed: {
    currentLayer() {
      return Interactions.getCurrentLayer(this.mapSet);
    },
    symbologyAttributes() {
      if (!this.currentLayer) return [];

      return this.currentLayer.fields();
    },
  },
  watch: {
    currentLayer: {
      immediate: true,
      handler(currentLayer) {
        if (!currentLayer) return;

        // setting of default on initial load and layer change
        this.symbologyAttribute = Interactions.getSymbologyAttribute(
          this.mapSet
        );
      },
    },
  },
  methods: {
    symbologyAttributeChange(symbologyAttribute) {
      Interactions.selectSymbologyAttribute(this.mapSet, symbologyAttribute);
    },
  },
};
</script>

<style lang="scss">
// duped for 3rd time here
.bg-transparent {
  .v-input__slot {
    background: transparent !important;
  }
}
</style>
