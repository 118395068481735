function getEnv(name) {
  return window?.config?.[name] || process.env[name];
}

const formatAmount = (amount, field, config, showDecimals = false) => {
  if (!amount) return '';

  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  if (field.includes('_dol') || field.includes('_value_usd')) {
    return amount.toLocaleString(userLocale || 'en-US', {
      style: 'currency',
      currency: config?.conversions?.currency?.alias || 'USD',
      ...(!showDecimals && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    });
  }

  if (showDecimals) {
    amount = Math.round(amount * 100) / 100; // preserve two decimals
  }

  return amount.toLocaleString(userLocale || 'en-US');
};

function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window.navigator.userAgent
  );
}

export {isMobile, getEnv, formatAmount};
