<template>
  <v-app>
    <!-- HEADER BAR -->
    <HeaderNav />
    <!-- BODY -->
    <v-main>
      <!-- main display is dependent on mobile vs desktop status -->
      <!-- (breakpoint width set in vuetify.js) -->

      <v-container fluid v-bind:class="{mobile: mobile}">
        <!-- TEXT INTRO SECTION -->
        <!--  currently only visible for non-mobile screen sizes -->
        <v-row
          v-if="!mobile && components.intro && components.intro.active"
          class="text-center bg-urban-light d-none d-md-flex">
          <v-col cols="2"></v-col>
          <v-col class="my-16" cols="8">
            <h1
              class="secondary--text display-1 font-weight-bold ma-5"
              v-html="components.intro && components.intro.title"></h1>
            <p
              class="subheading font-weight-regular ma-5"
              v-html="components.intro && components.intro.body"></p>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>

        <!-- TREE MAP -->
        <TreeMap
          v-if="
            components.treeMap &&
            components.treeMap.active &&
            (mobile ? activeTab == 'tree-map' : true)
          " />

        <!-- TEXT LEARN MORE SECTION -->
        <!-- currently only visible for non-mobile screen sizes -->
        <v-row
          v-if="components.learnMore && components.learnMore.active"
          class="text-center bg-urban-dark d-none d-md-flex"
          width="100%">
          <!-- km: row within row might be creating issues, though this isn't used by anyone -->
          <v-row no-gutters class="white--text my-16">
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <div
                class="text-h4 font-weight-bold my-4"
                v-html="components.learnMore.title"></div>
              <p class="subheading ma-4" v-html="components.learnMore.body"></p>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-row>

        <!-- CANOPY MAP -->
        <CanopyMap
          v-if="
            components.canopyMap &&
            components.canopyMap.active &&
            (mobile ? activeTab == 'canopy-map' : true)
          " />

        <!-- PARKS MAP -->
        <ParksMap
          v-if="
            components.parksMap &&
            components.parksMap.active &&
            (mobile ? activeTab == 'parks-map' : true)
          " />
      </v-container>
    </v-main>

    <v-bottom-navigation app v-if="mobile" v-model="activeTab" dark>
      <v-btn
        v-if="components.treeMap && components.treeMap.active"
        value="tree-map">
        <span>Tree Map</span>
        <v-icon>mdi-pine-tree</v-icon>
      </v-btn>
      <v-btn
        v-if="components.canopyMap && components.canopyMap.active"
        value="canopy-map">
        <span>Canopy Map</span>
        <v-icon>mdi-tree</v-icon>
      </v-btn>
      <v-btn
        v-if="components.parksMap && components.parksMap.active"
        value="parks-map">
        <span>Parks Map</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import MapSet from '@/shared/infrastructure/map-set';
import HeaderNav from './header-nav';
import TreeMap from '@/tree-map';
import CanopyMap from '@/canopy-map';
import ParksMap from '@/parks-map';
import {Store, Utils} from '@/shared';

const _getFirstActiveTab = components => {
  if (components.treeMap.active) {
    return 'tree-map';
  } else if (components.canopyMap.active) {
    return 'canopy-map';
  } else if (components.parksMap.active) {
    return 'parks-map';
  }
};

export default {
  name: 'Home',
  components: {CanopyMap, HeaderNav, ParksMap, TreeMap},
  props: ['mapName', 'mapSubName'],
  provide() {
    return {
      mapName: this.mapName, // within map set
      mapSubName: this.mapSubName, // within map set
      mapSetKey: this.mapSet.key(),
      mapSet: this.mapSet,
      embedded: this.embedded,
      // mobile?
    };
  },
  data() {
    return {
      components: {},
      activeTab: null,
      embedded: false, // not embedded if using this file
    };
  },
  computed: {
    mapSet() {
      return new MapSet(this.mapName, this.mapSubName);
    },

    mobile() {
      return Utils.isMobile() || this.$vuetify.breakpoint.mobile;
    },
  },
  beforeRouteEnter(to, _from, next) {
    const mapSet = new MapSet(to.params.mapName, to.params.mapSubName);

    Store.dispatch('fetchHostedConfig', mapSet).then(found => {
      if (!found)
        return next({
          name: 'NotFound',
          params: {
            catchAll: to.path.split('/').slice(1),
            mapName: to.path.slice(1),
          },
          query: to.query,
          hash: to.hash,
        });

      next();
    });
  },
  title() {
    const {intro} = this.$store.getters.hostedConfigComponents;
    return intro.title || 'Community Engagement Map';
  },
  mounted() {
    // i don't think this is loaded by the time mount is called
    this.components = this.$store.getters.hostedConfigComponents;
    this.activeTab = _getFirstActiveTab(this.components); // activeTab is different that component.active...
  },
};
</script>

<style scoped lang="scss">
.container.mobile {
  height: 100%;
  overflow: hidden;
}

.bg-urban-light {
  background: url(./images/bg-urban-light.jpg) no-repeat center center;
  background-size: cover;
}

.bg-urban-dark {
  background: url(./images/bg-urban-dark.png) no-repeat center center;
  background-size: cover;
}
</style>
