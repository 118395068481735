<template>
  <div class="fill-height">
    <Map store-module="parksMap" container-id="parks-map" :setup-map="setupMap">
      <div v-show="!currentFeature">
        <LayerSelect />
        <v-divider class="my-2"></v-divider>
        <LegendSymbology />
      </div>
      <!-- need v-if/v-else here too otherwise it inits FeatureDetails with null feature -->
      <FeatureDetails v-if="currentFeature" />
    </Map>

    <Work v-if="workEnabled" />
  </div>
</template>

<script>
import FeatureDetails from './feature-details';
import LayerSelect from './layer-select';
import LegendSymbology from './feature-details/legend-symbology';
import Map from '@/map';
import {UseCases} from '@/parks-map';
import Work from './feature-details/work';

export default {
  name: 'ParksMap',
  components: {FeatureDetails, LayerSelect, LegendSymbology, Map, Work},
  inject: ['mapSet'],
  provide() {
    return {
      storeModule: this.$store.state[this.mapSet.key()].parksMap,
      moduleKey: 'parksMap',
    };
  },
  computed: {
    currentFeature() {
      return this.$store.getters[
        `${this.mapSet.key()}/parksMap/currentFeature`
      ];
    },
    workEnabled() {
      return (
        this.currentFeature && this.currentFeature.layer().enableWorkRecords()
      );
    },
  },
  methods: {
    setupMap() {
      UseCases.setupMap(this.mapSet, this);
    },
  },
};
</script>
