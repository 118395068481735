<template>
  <v-app>
    <HeaderNav />
    <v-main>
      <v-container>
        <v-row class="text-center bg-urban-dark" width="100%">
          <v-row no-gutters class="white--text my-16">
            <v-col cols="3"></v-col>
            <v-col cols="6">
              <div class="text-h4 font-weight-bold my-4">Not Found</div>

              <p v-if="!home && !mapName" class="subheading ma-4">
                Nothing to see here
              </p>
              <p v-if="home">
                Nothing at the home page for this app, please visit a customer
                folder to see the Community Engagement Map, for example:
                <router-link to="/tpDemo">/tpDemo</router-link>
              </p>
              <p v-if="mapName" class="subheading ma-4">
                Unable to find configuration for map named:
                <strong>{{ mapName }}</strong>
                <br />
                (The map name is case sensitive)
              </p>
            </v-col>
            <v-col cols="3"></v-col>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HeaderNav from './header-nav';

export default {
  name: 'NotFound',
  components: {HeaderNav},
  props: ['mapName', 'home'],
};
</script>

<style scoped lang="scss">
.bg-urban-dark {
  background: url(./images/bg-urban-dark.png) no-repeat center center;
  background-size: cover;
}
</style>
