import {
  Map,
  Mapbox,
  WorkRecord,
  WorkRecordRepository,
  Adapter,
} from '@/parks-map';
import {Cypress, Store, Utils} from '@/shared';
import MapSetService from '@/shared/infrastructure/map-set/service';
import {MarketingLink, ToggleSatelliteButton} from '@/map';
import Vue from 'vue';
import FeaturePresenter from './feature-details/feature-presenter';

const ParksMapUseCases = {
  setupMap(mapSet, component) {
    MapSetService.storeWatchOnce(
      [mapSet.key(), 'parksMap', 'config'],
      config => {
        const embedded = Store.state.embedded;

        const afterRender = () => {
          const adapter = new Adapter(
            mapSet.mapName(),
            mapSet.mapSubName(),
            mapSet.key()
          );

          const {currentLayerId} = Store.state[mapSet.key()].parksMap;

          // does this need to happen for onLoadAndStyleChange (when new layer is selected?)
          adapter.addFeatureClickHandler();

          // this action can only be dispatched after Mapbox map is rendered
          // can replace with adapter.selectLayer once uncoupled from store
          Store.dispatch(
            `${mapSet.key()}/parksMap/selectLayer`,
            currentLayerId
          ); // default layer is set in Mapbox too, need to consolidate

          Cypress.exposeMapFeaturesLoaded(
            Mapbox.getMap().map,
            currentLayerId,
            'parksMapFeaturesLoaded'
          );
        };

        Mapbox.render({
          mapName: mapSet.mapName(),
          mapSecondName: mapSet.mapSubName(),
          geocoderCountries: config.map.geocoderCountries,
          zoom: config.map.defaultZoom,
          center: config.map.defaultCenter,
          embedded,
          afterRender,
        });

        // km: duped code 4 times here and a 5th for OnwardHomes
        this._addMapControl(ToggleSatelliteButton, component);
        this._addMapControl(
          MarketingLink,
          component,
          Utils.isMobile() ? 'top-right' : 'bottom-left'
        );
      }
    );
  },
  getViewCurrentFeature(mapSet) {
    // TODO use resository here instead of Store directly
    return new FeaturePresenter(
      Store.getters[`${mapSet.key()}/parksMap/currentFeature`],
      mapSet
    );
  },
  getShowWork(mapSet) {
    return Store.state[mapSet.key()].parksMap.showWork;
  },
  toggleWork(mapSetKey) {
    Store.dispatch(`${mapSetKey}/parksMap/toggleWork`);
  },
  getFilteredWorkRecords(mapSet) {
    const workRecords = WorkRecordRepository.getAllFromCurrentFeature(mapSet);
    let dateFilter = Store.state[mapSet.key()].parksMap.workRecordsDateFilter;

    if (!dateFilter) {
      const currentLayer =
        Store.getters[`${mapSet.key()}/parksMap/currentLayer`];
      dateFilter = WorkRecord.DateFilter.defaultValue(currentLayer);
    }

    return WorkRecord.DateFilter.filter(workRecords, dateFilter);
  },
  toggleSatellite(mapSetKey) {
    // ParksMapAdapter not necessary here
    Store.dispatch(`${mapSetKey}/parksMap/toggleSatellite`);

    const map = new Map();
    map.toggleSatelliteLayer();
  },
  _addMapControl(component, parent, location, propsData) {
    Mapbox.addCustomControl(
      new Vue({...component, parent, propsData}).$mount(),
      location
    );
  },
};

export default ParksMapUseCases;
