import * as _ from 'lodash';

const WorkRecordDateFilter = {
  WORK_RECORD_FILTER_TEXT: 'alias',
  WORK_RECORD_FILTER_VALUE: 'value',

  options(layer) {
    return _(layer.workRecordFilters())
      .sortBy(this.WORK_RECORD_FILTER_VALUE)
      .reverse()
      .value(); // this .value() unwraps the lodash wrapper
  },

  defaultValue(layer) {
    // first option in the recent past "last # days", typically 5 (value -5)
    return _.find(this.options(layer), filter => filter.value < 0).value;
  },

  filter(workRecords, daysOffset) {
    const dates = this._timeframeDates(daysOffset);

    return workRecords.filter(workRecord => {
      return workRecord.isBetween(...dates);
    });
  },

  _timeframeDates(daysOffset) {
    const nowDate = new Date();

    const cutoff = new Date(
      nowDate.setDate(nowDate.getDate() + daysOffset)
    ).getTime();

    const now = new Date().getTime();

    // right now this current time of day, not beginning/end of day
    if (daysOffset > 0) {
      // next # days
      return [now, cutoff];
    } else {
      // last # days
      return [cutoff, now];
    }
  },
};

export default WorkRecordDateFilter;
