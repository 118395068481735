import Vue from 'vue';
import vuetify from 'vuetify';
import {Variables} from '@/shared';

Vue.use(vuetify);

// available in button styles: primary, accent, error, info, success
const Vuetify = new vuetify({
  theme: {
    themes: {
      light: {
        // hardcoded are set as defaults
        primary: Variables.timberGreen,
        secondary: Variables.coniferGreen,
        accent: Variables.darkBlue,
        error: '#FF5252',
        info: Variables.perennialGreen,
        success: '#4CAF50',
        warning: '#FFC107',
        anchor: '#1976D2', // default is same as primary
      },
    },
  },
  // docs: https://vuetifyjs.com/en/features/breakpoints/#display-breakpoints
  breakpoint: {
    mobileBreakpoint: 'xs', // < 600px
  },
});

export default Vuetify;
