<template>
  <v-card class="mb-4">
    <v-card-title>{{ workRecord.title() }}</v-card-title>
    <v-card-subtitle v-html="workRecord.subtitle()"></v-card-subtitle>
    <v-card-text v-if="workRecord.comments()">{{
      workRecord.comments()
    }}</v-card-text>

    <v-card-actions class="pt-0 mt-n11" v-if="workRecord.photos().length">
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{on, attrs}">
          <v-btn
            icon
            @click="showPhotos = !showPhotos"
            color="primary"
            v-bind="attrs"
            v-on="on">
            <v-icon>{{ workRecord.photoIcon(showPhotos) }}</v-icon>
          </v-btn>
        </template>
        <span>{{ showPhotos ? 'Hide' : 'Show' }} Photos</span>
      </v-tooltip>
    </v-card-actions>

    <v-expand-transition>
      <v-card-text v-show="showPhotos" class="pa-0">
        <v-carousel
          v-model="currentPhotoIndex"
          height="auto"
          :show-arrows="!workRecord.singlePhoto()"
          :hide-delimiters="workRecord.singlePhoto()">
          <v-carousel-item
            v-for="(photo, index) in workRecord.photos()"
            :key="index">
            <v-img :src="photo" contain></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import WorkRecordPresenter from '../../../application/feature-details/work/work-record-presenter';

export default {
  props: ['model', 'layer'],
  data: () => ({
    showPhotos: false,
    currentPhotoIndex: 0,
  }),
  computed: {
    workRecord() {
      return new WorkRecordPresenter(this.model, this.layer);
    },
  },
};
</script>
