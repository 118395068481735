import {MapboxTreeMap} from '@/tree-map';
import {Store} from '@/shared';

const TreeMapUseCases = {
  // nearly identical function to ParksMapUseCases
  toggleSatellite(mapSetKey) {
    Store.dispatch(`${mapSetKey}/treeMap/toggleSatellite`);

    const map = new MapboxTreeMap();
    map.toggleSatelliteLayer();
  },
};

export default TreeMapUseCases;
