import {BaseMap} from '@/map';

const LAYER_TO_INSERT_BEFORE_ID = 'road-label-simple';

// TODOs: move mapbox code down to isolate all mapbox API code,
//   & put code related to mapbox that is duplicated in all maps in BaseMap
// The goal is for this (and other files that inherit from BaseMap) file to encapsulate
//   as much Mapbox know-how as possible, the rest falls to MapAdapter
class CanopyMap extends BaseMap {
  addSource(sourceId, {type, tileUrl, tileSize, maxzoom = 15} = {}) {
    const sourceOptions = {
      type,
      tiles: [tileUrl],
      maxzoom, // ap 12/8/21: Only request tiles up to z15, overzoom after that
    };

    if (tileSize) Object.assign(sourceOptions, {tileSize});

    this._map.addSource(sourceId, sourceOptions);
  }

  addRasterLayer(layerId, sourceId) {
    this._map.addLayer({id: layerId, type: 'raster', source: sourceId});
  }

  // TODO: move this up like the fill layer
  addVectorLineLayer(layerId, sourceId, sourceLayer) {
    // TODO: move this up to vector-layer.js just like adding source details
    this.addLayer(layerId, sourceId, sourceLayer, 'line', {
      paint: {
        'line-color': '#00FFFF',
        'line-width': 5,
        'line-opacity': 0.4,
      },
      layout: {
        'line-cap': 'round',
      },
      filter: ['in', 'id', ''],
    });
  }

  // this func and currentLayer() don't interact with mapbox map, but are specific
  //   to how showing/hiding layers would happen, and this abstracts it a bit. So,
  //   leaving this in here because it is coupled to mapbox and currentLayer is
  //   stored elsewhere for usage within core/domain app
  selectLayer(layer) {
    if (this._currentLayer) this._currentLayer.hide();
    layer.show();
    this._currentLayer = layer;
  }

  currentLayer() {
    return this._currentLayer;
  }

  updateFillColorExpression(layerId, expression) {
    this._map.setPaintProperty(layerId, 'fill-color', expression);
  }

  getLayer(layerId) {
    return this._map.getLayer(layerId);
  }

  hideLayer(layerId) {
    // might need if to see if layer already exists before hiding
    this._map.setLayoutProperty(layerId, 'visibility', 'none');
  }

  showLayer(layerId) {
    this._map.setLayoutProperty(layerId, 'visibility', 'visible');
  }

  addLayer(layerId, sourceId, sourceLayer, type, options) {
    const layer = Object.assign(
      {
        id: layerId,
        type,
        source: sourceId,
        'source-layer': sourceLayer,
      },
      options
    );

    /* tp 8/27/21: This is the highest label layer in our current Mapbox Style,
     * however our canopy layer is still getting inserted on top of every other
     * layer, which hides labels. Might be a bug in Mapbox version we're on. */
    this._map.addLayer(layer, LAYER_TO_INSERT_BEFORE_ID);
  }

  queryFeature(point) {
    const renderedFeatures = this._map.queryRenderedFeatures(point, {
      layers: [this._currentLayer.id()],
    });

    return renderedFeatures.length && renderedFeatures[0];
  }

  updateFeatureHighlight(pid = '') {
    // don't add highlight to raster/imagery layer. using instanceof creates circular dependency
    if (!this._currentLayer.isRasterType())
      this._map.setFilter(this._currentLayer.highlightId(), ['in', 'pid', pid]);
  }
}

const singleton = new CanopyMap();
// Object.freeze(singleton); // km 1/1/20: this is because we need all references
// freeze results in not being able to set this._map later (Cannot assign to read only property)
// to the map to only be this one instance and this class provides the interface

export default singleton;
