import {BaseMap} from '@/map';
import TreeMapMapbox from './index';

class MapboxTreeMap extends BaseMap {
  constructor(map = TreeMapMapbox.getMap().map) {
    super(map);
  }
}

// const singleton = new MapboxTreeMap();
// Object.freeze(singleton); // km 1/1/20: this is because we need all references
// // to the map to only be this one instance and this class provides the interface

// export default singleton;

export default MapboxTreeMap; // no singleton while ParksMapMapbox is managing the Mapbox map instance
