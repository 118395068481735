<template>
  <!-- need v-if so v-select doesn't break before layers is available -->
  <div v-if="layers">
    <v-select
      v-if="layers.length > 1"
      v-model="localLayer"
      :items="layers"
      :item-text="item => item.name()"
      @change="layerChange"
      :return-object="true"
      flat
      solo
      class="text-subtitle-1 font-weight-bold mx-2 mb-n10 bg-transparent">
    </v-select>
    <div v-else class="text-subtitle-1 font-weight-bold mx-5 mt-2">
      {{ currentLayer && currentLayer.name() }}
    </div>
  </div>
</template>

<script>
import {Interactions} from '@/canopy-map';

export default {
  props: ['currentLayer'],
  inject: ['mapSet'],
  data() {
    return {
      localLayer: null,
    };
  },
  computed: {
    layers() {
      return Interactions.getLayers(this.mapSet);
    },
  },
  watch: {
    /* km 12/2/21: can't use a computed data value because it needs to get set
     * from the select, can't set in mounted because it's loaded async and not
     * available yet, so using a watcher */
    currentLayer: {
      immediate: true, // km 12/2/21: immediate makes sure this runs the first time CanopyLayerSelect is loaded
      handler(currentLayer) {
        if (!currentLayer) return;
        this.localLayer = currentLayer;
      },
    },
  },
  methods: {
    layerChange(newLayer) {
      Interactions.selectLayer(this.mapSet, newLayer);
    },
  },
};
</script>

<style lang="scss">
.bg-transparent {
  .v-input__slot {
    background: transparent !important;
  }
}
</style>
