<template>
  <div class="histogram-container py-1">
    <div class="text-subtitle-1 font-weight-medium mx-2">
      <div
        v-bind:style="{'background-color': service.averageColor()}"
        class="legend-single-line mb-1 mr-1"></div>
      {{ service.selectionLabel() }}:
      <span
        v-bind:style="{color: service.averageColor()}"
        class="font-weight-black">
        {{ averageFormatted }}
      </span>
    </div>
    <div v-if="polygon" class="text-subtitle-1 font-weight-medium mx-2">
      <div class="selection-line legend-single-line mb-1 mr-1"></div>
      Selection:
      <span class="selection-value font-weight-black">
        {{ selectionValue }}
      </span>
    </div>
    <div id="histogram"></div>
    <div
      v-bind:style="{background: service.gradientCss()}"
      class="legend-scale ml-16 mr-3 mb-3 mt-n5"></div>
  </div>
</template>

<script>
import HistogramService from '../../application/details-overlay/histogram/service';
import {Interactions} from '@/canopy-map';

export default {
  inject: ['mapSet'],

  props: {
    currentLayer: {type: Object},
    polygon: {type: Object},
  },

  data: () => ({
    averageFormatted: '',
    selectionValue: null,
  }),

  computed: {
    service() {
      const service = new HistogramService(
        this.currentLayer,
        this.symbologyAttribute
      );
      return service;
    },

    symbologyAttribute() {
      return Interactions.getSymbologyAttribute(this.mapSet);
    },
  },

  watch: {
    /* km 12/2/21: can't use a computed data value because it needs to get set
     * from the select, can't set in mounted because it's loaded async and not
     * available yet, so using a watcher */
    // km: could use computed? following TreeDetails
    currentLayer: {
      handler() {
        this.setupChart();
      },
    },

    symbologyAttribute: {
      handler() {
        this.setupChart();
      },
    },

    polygon: {
      handler(polygon) {
        this.setupPolygon(polygon);
      },
    },
  },

  // this ends up getting called when toggleSource re-adds the details overlay via rasterSource in canopy-map.vue
  mounted() {
    // update histogram with selection if still set
    this.setupPolygon(this.polygon);

    this.setupChart();
  },

  methods: {
    setupChart() {
      this.service.setupChart();

      // have to assign to data, because using these values directly or via computed doesn't update value on toggleSource
      this.averageFormatted = this.service.averageFormatted();
    },

    setupPolygon(polygon) {
      this.service.addLineForSelectedPolygon(polygon);
      this.selectionValue = this.service.selectionValue();
    },
  },
};
</script>

<style scoped lang="scss">
.histogram-container {
  width: 100%;
}

#histogram {
  height: 240px;
}

.legend-single-line {
  display: inline-block;
  height: 6px;
  width: 30px;
}

.legend-scale {
  height: 30px;
}

.selection-value {
  color: #00ffff;
}

.selection-line {
  background-color: #00ffff;
}
</style>
