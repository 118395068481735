<template>
  <div class="fill-height">
    <!-- INTRO TEXT -->
    <!-- currently only visible for non-mobile screen sizes and embedded implementation -->
    <v-row
      v-if="!mobile"
      class="primary white--text cem-header-row text-center d-md-flex">
      <v-col cols="3"></v-col>
      <v-col cols="6">
        <div class="text-h4 font-weight-bold my-3" v-html="content.title"></div>
        <p class="subheading mb-6" v-html="content.body"></p>
      </v-col>
      <v-col cols="3"></v-col>
    </v-row>

    <v-row
      class="flex-column-mobile"
      v-bind:class="{mobile, 'mobile-embedded': mobile && embedded}">
      <div
        :id="containerId"
        class="map-container"
        v-bind:class="{mobile}"></div>

      <DetailsOverlay v-if="!hideOverlay">
        <slot></slot>
      </DetailsOverlay>
    </v-row>
  </div>
</template>

<script>
import DetailsOverlay from './details-overlay';
import {Utils} from '@/shared';

export default {
  components: {DetailsOverlay},
  props: ['containerId', 'hideOverlay', 'setupMap'], // hideOverlay used by Canopy map
  inject: ['storeModule'],
  computed: {
    mobile() {
      return Utils.isMobile() || this.$vuetify.breakpoint.mobile;
    },
    embedded() {
      return this.$store.state.embedded;
    },
    content() {
      const mapModule = this.storeModule;

      return (mapModule && mapModule.config && mapModule.config.content) || {};
    },
  },
  mounted() {
    this.setupMap();
  },
};
</script>

<style scoped lang="scss">
.map-container {
  width: 100%;
  height: 600px;
  flex-grow: 1;
  z-index: 1;
  position: absolute;

  &.mobile {
    height: 100%;
  }
}

.flex-column-mobile {
  position: relative;
  height: 600px;

  &.mobile {
    height: 100%;
  }

  &.mobile-embedded {
    margin-top: 0px;
  }
}
</style>
