import {Cypress} from '@/shared';
import MapboxCanopyMap from '../../interface/mapbox/canopy-map';
import {MarketingLink} from '@/map';
import RasterLayer from '../../interface/mapbox/raster-layer';
import SourceToggle from '../../interface/map-controls/source-toggle';
import VectorLayer from '../../interface/mapbox/vector-layer';
import Vue from 'vue';

const MAP_STRING_NAME = 'canopy-map'; // should this be here?

// think about: what would we have to re-write if we change out mapbox for something else?
// this is supposed to provide friendly interface in CEM biz domain lingo, and then
//   translate to mapbox world. nothing above this should know about mapbox stuff,
//   things in this file and in other mapbox-specific files can know things about
//   mapbox details
// does this belong in infra/interface/application?
const MapAdapter = {
  setup({includeHash, zoom, center, mobileDragWorkarounds}) {
    MapboxCanopyMap.render({
      container: MAP_STRING_NAME,
      hash: includeHash && MAP_STRING_NAME,
      zoom,
      center,
    });

    MapboxCanopyMap.disableScrollZoom();
    if (mobileDragWorkarounds) MapboxCanopyMap.setupMobileDragWorkarounds();
  },

  updateZoomAndCenter(params) {
    MapboxCanopyMap.updateZoomAndCenter(params);
  },

  addMapControls(geocoderOptions) {
    MapboxCanopyMap.addGeocoderControl(geocoderOptions);

    MapboxCanopyMap.addNavigationControl();
    MapboxCanopyMap.addGeolocateControl();
    MapboxCanopyMap.addAttributionControl();
  },

  addSourceToggleControl(parentComponent) {
    MapboxCanopyMap.addCustomControl(
      new Vue({...SourceToggle, parent: parentComponent}).$mount(),
      'top-left'
    );
  },

  addMarketingLinkControl(parentComponent) {
    MapboxCanopyMap.addCustomControl(
      new Vue({...MarketingLink, parent: parentComponent}).$mount(),
      'bottom-left'
    );
  },

  addFeatureClickHandler(setSelectedFeature) {
    MapboxCanopyMap.onClick(({point}) => {
      const feature = MapboxCanopyMap.queryFeature(point);

      // TODO: clean this up once polygon/feature is run through store/repo
      if (feature) {
        setSelectedFeature(feature);

        let {pid} = feature.properties;
        if (pid) MapboxCanopyMap.updateFeatureHighlight(pid); // not sure why `if (pid)` is needed
      } else {
        setSelectedFeature(null);
        MapboxCanopyMap.updateFeatureHighlight();
      }
    });
  },

  addVectorLayerImmediatelyAndOnLoad(layer, vectorLayerTileUrl) {
    // FIXME: see note in Cypress.exposeMapFeaturesLoaded. This must be called inside onLoadAndStyleChange though
    Cypress.exposeMapFeaturesLoaded(
      MapboxCanopyMap._map, // related to FIXME above, passing in private instance var for now
      layer.id(),
      'canopyMapFeaturesLoaded'
    );

    const vectorLayer = new VectorLayer(layer); // translate from TP/CEM to Mapbox here

    // immediate add layer
    try {
      MapAdapter._addSourceAndLayer(vectorLayer, vectorLayerTileUrl);
    } catch (error) {
      console.warn(
        'Error adding source/layer for canopy map. Trying again after map style load. \n',
        error
      );

      // on load add layer
      MapboxCanopyMap.onLoadAndStyleChange(() => {
        MapAdapter._addSourceAndLayer(vectorLayer, vectorLayerTileUrl);
      });
    }
  },

  selectLayer(layer) {
    let mapboxLayer;

    // could do `layer instanceof RasterLayer`, but then there would be two classes
    //   of RasterLayer in this file, so using instance function/method instead
    if (layer.isImageryType()) {
      // TODO: map layer attrs instead of just passing in the object itself
      mapboxLayer = new RasterLayer(layer);
    } else {
      mapboxLayer = new VectorLayer(layer);
    }

    MapboxCanopyMap.selectLayer(mapboxLayer);
  },

  updateSymbologyAttribute(name, steps) {
    const layer = MapboxCanopyMap.currentLayer();
    layer.updateSymbology(name, steps); // function is only for VectorLayer
  },

  _addSourceAndLayer(vectorLayer, vectorLayerTileUrl) {
    vectorLayer.addSource(vectorLayerTileUrl);
    MapboxCanopyMap.selectLayer(vectorLayer);
  },
};

export default MapAdapter;
