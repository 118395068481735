import {BaseMapStoreModule} from '@/map';
import {Config} from '@/shared';

const FETCH_CONFIG = 'FETCH_CONFIG';

const TreeMapStoreModule = BaseMapStoreModule.extend({
  state: {},
  mutations: {},
  actions: {
    fetchConfig({commit}, mapSet) {
      return Config.loadTreeMap(mapSet.mapName(), mapSet.mapSubName()).then(
        config => {
          commit(FETCH_CONFIG, config);
        }
      );
    },
  },
});

export default TreeMapStoreModule;
