import ColorScale from '../domain/color-scale';
import ChromaQuantileDiscreetColorScale from '../infrastructure/chroma-quantile-discreet-color-scale';

class ColorScaleService {
  constructor(layer, field) {
    this._colorScale = new ColorScale(layer, field);
  }

  getColorSteps() {
    this._scalesSetup();

    const stepBreaks = this._colorScale.stepBreaks();
    stepBreaks.pop(); // pop last off because we only need as many steps as colors, don't need the last break

    return stepBreaks.map(step => [step, this._getColor(step)]);
  }

  calculateColor(input) {
    this._scalesSetup();
    return this._getColor(input);
  }

  gradientZeroColorPercent() {
    // must setQuantileBreaks before getting zero location
    this._setupQuantileBreaks();
    return this._colorScale.gradientZeroColorPercent();
  }

  getColorScale() {
    return this._colorScale;
  }

  _scalesSetup() {
    // must setQuantileBreaks before creating scales
    this._setupQuantileBreaks();
    this._createScales();
  }

  _setupQuantileBreaks() {
    const quantileBreaks = ChromaQuantileDiscreetColorScale.quantileBreaks(
      this._colorScale.attributeData()
    );

    const uniqueQuantileBreaks = [...new Set(quantileBreaks)];

    this._colorScale.setQuantileBreaks(uniqueQuantileBreaks);
  }

  _getColor(input) {
    const scale = this._getScale(input);
    return scale(input);
  }

  _createScales() {
    this._scales = this._colorScale.scaleSetupData().map(scale => {
      return ChromaQuantileDiscreetColorScale.getColorStepScale(scale);
    });
  }

  // _getScale() is an important implementation detail, not easy to fit into
  // the domain as it doesn't fit in colorScale entity but is at a colorScale
  // aggregate level
  _getScale(input) {
    if (!this._colorScale.supportNegativeValues()) {
      return this._scales[0];
    } else {
      return input < 0 ? this._scales[0] : this._scales[1];
    }
  }
}

export default ColorScaleService;
