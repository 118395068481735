class MapSet {
  constructor(mapName, mapSubName) {
    this._mapName = mapName;
    this._mapSubName = mapSubName;
  }

  key() {
    return (this._key ||= this._buildKey());
  }

  mapName() {
    return this._mapName;
  }

  mapSubName() {
    return this._mapSubName;
  }

  // private

  // i.e. 'exampleClient/exampleMap:tree-map&canopy-map' or 'exampleMap:tree-map'
  _buildKey() {
    const secondNameString = this._mapSubName ? `|${this._mapSubName}` : '';

    return `${this._mapName}${secondNameString}`;
  }
}

export default MapSet;
