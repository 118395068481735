import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

let chart,
  categoryAxis,
  ranges = [];

am4core.useTheme(am4themes_animated);

const am4themes_canopyTheme = target => {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color('rgb(175, 221, 244)'),
      am4core.color('rgb(133, 206, 241)'),
      am4core.color('rgb(113, 195, 242)'),
      am4core.color('rgb(92, 181, 225)'),
      am4core.color('rgb(63, 140, 205)'),
    ];
  }
};

am4core.useTheme(am4themes_canopyTheme);

// more of an infrastructure file, but lots of UI styling stuff here so in /interface for now

const createXYChart = (elementId, data) => {
  // self._disposeChart();
  chart = am4core.create(elementId, am4charts.XYChart);

  chart.data = data;
};

const addXAxis = (axis, unit) => {
  categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

  categoryAxis.dataFields.category = axis;

  _formatAxis(categoryAxis);

  categoryAxis.renderer.labels.template.adapter.add(
    'text',
    text => `${text}${unit}`
  );
};

const addYAxis = title => {
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  _formatAxis(valueAxis, title);
};

const addSeries = (xAxis, yAxis) => {
  const series = chart.series.push(new am4charts.LineSeries());
  // const series = chart.series.push(new am4charts.ColumnSeries()); // bar chart

  series.smoothing = 'monotoneX'; // also: 'monotoneY' or 'bezier'

  series.strokeWidth = 2; // px
  series.stroke = am4core.color('#000');
  series.fillOpacity = 0.1;
  series.fill = am4core.color('#000');

  series.dataFields.categoryX = xAxis;
  series.dataFields.valueY = yAxis;
};

const addSingleLine = ({value, color = '#00FFFF'}) => {
  const range = categoryAxis.axisRanges.create();

  range.category = value;
  range.label.disabled = true;
  range.grid.stroke = am4core.color(color);
  range.grid.strokeWidth = 6;
  range.grid.strokeOpacity = 1;
  range.grid.above = true; // displays over the series line

  ranges[value] = range; // add so it can be removed later
};

const removeSingleLine = value => {
  /* won't remove average line as that index/value will have been replaced if
   * it was the same as selection line */
  categoryAxis.axisRanges.removeValue(ranges[value]);
  delete ranges[value];
};

const _formatAxis = (axis, title = '') => {
  axis.title.text = title;

  axis.renderer.labels.template.fontSize = 12;
  axis.renderer.minGridDistance = 30; // add more increments on the axis
  axis.renderer.grid.template.disabled = true; // hide gridlines
  axis.renderer.line.strokeOpacity = 1;
  axis.renderer.line.strokeWidth = 2;
  axis.renderer.line.stroke = am4core.color('#000');
};

export default {
  createXYChart,
  addXAxis,
  addYAxis,
  addSeries,
  addSingleLine,
  removeSingleLine,
};
